import React from "react";
import { AccessTime } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";

const TMaxAttendants = ({ attendants, classes, time }) => {
  const theme = useTheme();
  return (
    <div className={`cmp-dashboard__raw-data-item--first ${classes.dashBoardFirstItem}`}>
      <div>
        <h2 style={{color: theme.palette.blackOrWhite, fontWeight: "400"}}>T.Max Atend</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <AccessTime />
        <span>{time}</span>
      </div>
    </div>
  );
};

export default TMaxAttendants;
