import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";

import { useTheme } from "@material-ui/core/styles";

import MainContainer from "../../components/MainContainer";
import api from "../../services/api";
import useStyles from "./style";
import BlackListEditContactModal from "../../components/BlacklistEditContactModal";
import BlackListHeader from "./BlacklistComponent/components/BlacklistHeader";
import BlackListTable from "./BlacklistComponent/components/BlacklistTable";

const BlackList = () => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const [blacklist, setBlacklist] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const previousSearchTerm = useRef("");
    const [isEdit, setIsEdit] = useState(false);
    const debounceTimeout = useRef(null);

    const fetchBlacklist = useCallback(async (searchTerm) => {
        if (searchTerm === previousSearchTerm.current) {
            return;
        }

        previousSearchTerm.current = searchTerm;

        try {
            setIsLoading(true);
            const response = await api.get(`/blacklist?page=${currentPage}&searchTerm=${searchTerm}`);
            setBlacklist(response.data.data);
            setTotalPages(response.data.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("Failed to load blacklist data.");
        }
    }, [currentPage]);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/blacklist?page=${currentPage}&searchTerm=${searchTerm}`);
                setBlacklist(response.data.data);
                setTotalPages(response.data.totalPages);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error("Failed to load blacklist data.");
            }
        }

        fetch();
    }, [currentPage]);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            fetchBlacklist(searchTerm);
        }, 1000);

        return () => clearTimeout(debounceTimeout.current);
    }, [searchTerm, fetchBlacklist]);

    const theme = useTheme();

    return (
        <MainContainer>
            <BlackListHeader
                theme={theme}
                setIsLoading={setIsLoading}
                setBlacklist={setBlacklist}
                setTotalPages={setTotalPages}
                currentPage={currentPage}
                setModalOpen={setOpenModal}
                setEdit={setIsEdit}
            />
            <BlackListTable
                classes={classes}
                isLoading={isLoading}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                blacklist={blacklist}
                setCurrentPage={setCurrentPage}
                setSelectedContact={setSelectedContact}
                setOpenModal={setOpenModal}
                theme={theme}
                setBlacklist={setBlacklist}
                totalPages={totalPages}
                currentPage={currentPage}
                setIsEdit={setIsEdit}
            />

            <BlackListEditContactModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                setSelectedContact={setSelectedContact}
                selectedContact={selectedContact}
                setBlacklist={setBlacklist}
                blacklist={blacklist}
                isEdit={isEdit}
            />
        </MainContainer>
    );
};

export default BlackList;
