import React, { useState, useEffect, useRef, useContext, useCallback } from "react";


import useTickets from "../../hooks/useTickets";

import "./dashboard.css"
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import moment from "moment";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardFilter from "../../components/DashboardFilter";
import DashboardGraphs from "../../components/DashboardGraphs";
import useStyles from "./theme";
import { useTheme } from "@material-ui/core";
import DashboardSubheader from "../../components/DashboardSubheader";
import { AuthContext } from "../../context/Auth/AuthContext";
import DashboardContext from "../../context/DashboardContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import fetchData from "./DashboardComponents/functions/fetchData";
import TabsHeader from "./DashboardComponents/Components/TabsHeader";
import GroupInfo from "./DashboardComponents/Components/GroupsInfo";
import MessageTable from "../../components/MessagesTable";

const Dashboard = () => {
  const theme = useTheme();

  const classes = useStyles(theme);
  const {
    counters, setCounters,
    attendants, setAttendants,
    lastTickets, setLastTickets,
    allTicketsCount, setAllTicketsCount,
    filterType, setFilterType,
    reportCount, setReportCounts,
    period, setPeriod,
    companyDueDate, setCompanyDueDate,
    dateTo, setDateTo, dateFrom,
    setDateFrom, serviceQuality,
    setServiceQuality
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const { find, getReport, getServiceQuality } = useDashboard();
  const { finding } = useCompanies();
  const { user } = useContext(AuthContext);
  const [groupGraphicsActive, setGroupGraphicsActive] = useState("privado");
  const [groupsFound, setGroupsFound] = useState(null);
  const [groupList, setGroupsNameList] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [privateHeatmapFound, setPrivateHeatmapFound] = useState(null);
  
  useEffect(() => {
    const getGroupMessages = async () => {
      const { data } = await api.get("/group-messages-report", { params: { startDate: dateFrom, endDate: dateTo } });
      setGroupsFound(data);
    }
    const getPrivateMessages = async () => {
      const { data } = await api.get("/privateMessages", {
        params: {
          startDate: dateFrom,
          endDate: dateTo
        },
      });
      setPrivateHeatmapFound(data);
    };

    if (!groupsFound && groupGraphicsActive !== "privado") {
      try {
        getGroupMessages();
      } catch (error) {
        toastError(error);
      }
    }

    if (groupGraphicsActive === "privado") {
      try {
        getPrivateMessages();
      } catch (error) {
        toastError(error)
      }
    }
    const getGroupsList = async () => {
      const [groupsResponse] = await Promise.all([
        api.get("/tickets/groups"),
      ]);

      setGroupsNameList(
        groupsResponse.data.map((group) => ({
          id: group.id,
          name: group.name,
          ticketId: group.ticketId
        }))
      );
    }
    if (groupGraphicsActive !== "privado") {
      getGroupsList();
    }
  }, [groupGraphicsActive]);

  useEffect(() => {
    const getGroupMessages = async () => {
      const { data } = await api.get("/group-messages-report", { params: { startDate: dateFrom, endDate: dateTo, groupId: selectedGroupId } });
      setGroupsFound(data);
    }
    getGroupMessages();
  }, [selectedGroupId]);

  if (companyDueDate && false) {
    console.log("");
  }

  const mimicFetchData = useCallback(fetchData, []);
  const mimicFetchServiceQuality = useCallback(fetchServiceQuality, []);

  useEffect(() => {
    async function firstLoad() {
      await mimicFetchData(setLoading, dateFrom, dateTo, selectedGroupId, setGroupsFound, find, user, setCounters, setAllTicketsCount, setLastTickets, setAttendants, period, groupList, page, searchTerm, setMessages, setTotalMessages, selectedUser, setPrivateHeatmapFound);
      await mimicFetchServiceQuality();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);

  }, [mimicFetchData, mimicFetchServiceQuality]);

  async function fetchServiceQuality() {
    if (companyId) {
      try {
        const data = await getServiceQuality(companyId);
        setServiceQuality(data.serviceQualityPercentage);
      } catch (error) {
        console.error("Erro ao buscar qualidade do serviço:", error);
      }
    }
  }

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchCompaniesData() {
    await loadCompanies();
    const data = await find();
    setLastTickets(data?.tickets || []);
    setAllTicketsCount(data ? data : [])
  }

  async function fetchReport() {
    if (lastTickets?.length === 0 || !reportCount) {
      const data = await getReport({
        searchParam: "",
        whatsappId: [],
        users: [],
        queueIds: [],
        status: [],
        categoryIds: [],
        dateFrom: dateFrom,
        dateTo: dateTo,
        page: 1,
        pageSize: 20,
      });

      setReportCounts(data);
    }
  };

  const mimicFetchCompaniesData = useCallback(fetchCompaniesData, []);
  const mimicFetchReport = useCallback(fetchReport, []);

  useEffect(() => {
    mimicFetchCompaniesData();
    mimicFetchReport()

  }, [mimicFetchCompaniesData, mimicFetchReport]);

  const companyId = localStorage.getItem("companyId");

  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
    }
    setLoading(false);
  };

  const date = useRef(new Date().toISOString());
  const { tickets } = useTickets({ date: date.current });

  return (
    <div>
      <TabsHeader
        setGroupGraphicsActive={setGroupGraphicsActive}
        groupGraphicsActive={groupGraphicsActive}
        theme={theme} selectedGroupId={selectedGroupId}
        setSelectedGroupId={setSelectedGroupId}
        groupsFound={groupsFound} searchTerm={searchTerm}
        groupList={groupList}
        startDate={dateFrom}
        user={user}
        endDate={dateTo}
        selectedUser={selectedUser}
      />

      <div className={classes.container}>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          {groupGraphicsActive !== "grupos" && <DashboardHeader groupsFound={groupsFound} isAGroup={groupGraphicsActive === "grupos"} isSuper={user.super} classes={classes} attendants={attendants} counters={counters} serviceQuality={serviceQuality} />}
          {(groupGraphicsActive !== "grupos" && !user.super) && <DashboardSubheader groupsFound={groupsFound} classes={classes} attendants={attendants} counters={counters} reportCount={allTicketsCount} isAGroup={groupGraphicsActive === "grupos"} />}
        </div>

        <DashboardGraphs
          classes={classes} isSuper={user.super}
          theme={theme} attendants={attendants}
          counters={counters} lastTickets={lastTickets}
          serviceQuality={serviceQuality} groupsFound={groupsFound}
          tickets={tickets} isAGroup={groupGraphicsActive === "grupos"}
          privateHeatmapFound={privateHeatmapFound}
        />
        {!user.super && <DashboardFilter
          filterType={filterType} dateFrom={dateFrom}
          dateTo={dateTo} setDateFrom={setDateFrom}
          classes={classes} setDateTo={setDateTo}
          period={period} loading={loading}
          handleChangePeriod={handleChangePeriod}
          handleChangeFilterType={handleChangeFilterType}
          fetchData={fetchData} selectedGroupId={selectedGroupId}
          setGroupsFound={setGroupsFound} find={find}
          user={user} setCounters={setCounters}
          setAllTicketsCount={setAllTicketsCount}
          setLastTickets={setLastTickets} setAttendants={setAttendants}
          setLoading={setLoading}
          groupList={groupList} page={page} searchTerm={searchTerm} setMessages={setMessages}
          setTotalMessages={setTotalMessages} selectedUser={selectedUser}
          setPrivateHeatmapFound={setPrivateHeatmapFound}
        />}

        {!user.super && <GroupInfo
          groupGraphicsActive={groupGraphicsActive}
          theme={theme}
          groupsFound={groupsFound}
          attendants={attendants.length > 0 ? attendants.filter((attendant) => attendant.online)?.length : 0}
        />}
        {(groupGraphicsActive === "grupos" && !user.super) && <MessageTable
          groupList={groupList}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          selectedGroupId={selectedGroupId}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          ticketId={
            groupList.find((groupFound) => Number(groupFound.id) === Number(selectedGroupId))?.ticketId
              ? null
              : groupList.find((groupFound) => Number(groupFound.id) === Number(selectedGroupId))?.ticketId
          }
          startDate={dateFrom}
          endDate={dateTo}
          messages={messages}
          setMessages={setMessages}
          totalMessages={totalMessages}
          setTotalMessages={setTotalMessages}
          page={page}
          setPage={setPage}
          users={attendants}
        />}
        {!user.super && <div className={"cmp-dashboard__attendants-table"}>
          {attendants.length ? (
            <TableAttendantsStatus
              counters={counters}
              attendants={attendants}
              loading={loading}
              classes={classes}
              groupsFound={groupsFound}
              isAGroup={groupGraphicsActive === "grupos"}
              groupList={groupList}
              startDate={dateFrom}
              endDate={dateTo}
              
            />
          ) : null}
        </div>}
      </div>
    </div>
  );
};

export default Dashboard;
