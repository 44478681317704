import { useTheme } from '@material-ui/core';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DonutChart = ({ pendent, done, ongoing, classes, fontColor }) => {
  const theme = useTheme();

  const data = {
    labels: ['Pendentes', 'Realizados', 'Em Andamento'],
    datasets: [
      {
        data: [pendent, done, ongoing],
        backgroundColor: [
          '#FF4500',
          'rgba(54, 162, 235, 0.2)',
          '#FFA500',
        ],
        hoverBackgroundColor: [
          '#E63900',
          'rgba(54, 162, 235, 0.5)',
          '#FF8C00',
        ],
        borderWidth: 1,
        borderColor: '#ffffff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%',
    legend: {
      labels: {
          fontColor: theme.palette.blackOrWhite
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <div className={classes.graphsBox} style={{ width: '100%', height: '252px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem' }}>
      <div style={{width: "100%"}}>
        <label style={{color: theme.palette.blackOrWhite, fontWeight: "400", fontSize: "16px"}}>Atendimentos</label>
      </div>
      <div style={{ width: '100%', height: '252px' }}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default DonutChart;
