import React, { useEffect, useState, useRef } from "react";
import { CircularProgress, List, ListItem, Typography } from "@material-ui/core";
import { format } from "date-fns";
import api from "../../services/api";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import whatsBackground from "../../assets/wa-background.png";
import { PictureAsPdf, Description } from '@material-ui/icons';
import whatsBackgroundDark from "../../assets/wa-background-dark.png";

const useStyles = makeStyles((theme) => ({
  modalContentStyles: {
    backgroundColor: "white",
    padding: "1rem",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
    borderRadius: "8px",
    backgroundImage: theme.mode === 'light' ? `url(${whatsBackground})` : `url(${whatsBackgroundDark})`,
    ...theme.scrollbarStyles,
  }
}));

const SpyTimelineModal = ({ ticketId, pageNumber, ticketTrakingId, untilDate, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const audioRefs = useRef({});
  const theme = useTheme();
  const classes = useStyles();
  const modalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  };

  const messageContainerStyles = {
    width: "70%",
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    ...theme.scrollbarStyles
  };

  const messageFromMeStyles = {
    ...messageContainerStyles,
    alignItems: "flex-end",
    backgroundColor: "#DCF8C6",
    color: "black",
    borderRadius: "10px",
    padding: "10px",
    marginLeft: "auto",
  };

  const messageFromOthersStyles = {
    ...messageContainerStyles,
    alignItems: "flex-start",
    backgroundColor: "#FFFFFF",
    color: "black",
    borderRadius: "10px",
    padding: "10px",
    marginRight: "auto",
  };
  const handlePlayAudio = (audioId) => {
    Object.keys(audioRefs.current).forEach((key) => {
      if (key !== audioId && audioRefs.current[key]) {
        audioRefs.current[key].pause();
        audioRefs.current[key].currentTime = 0;
      }
    });

    if (audioRefs.current[audioId]) {
      audioRefs.current[audioId].play();
    }
  };

  const renderFile = (url, type, id, date, body) => {
    if (type.startsWith("audio")) {
      return (
        <div style={{ marginTop: "10px" }}>
          <audio
            ref={(el) => {
              if (el) audioRefs.current[id] = el;
            }}
            controls
            onPlay={() => handlePlayAudio(id)}
          >
            <source src={url} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginTop: "5px" }}
          >
            {`Data: ${format(new Date(date), "dd/MM/yyyy HH:mm")}`}
          </Typography>
        </div>
      );
    }
    // Verifica se o tipo de mídia é imagem
    else if (type.startsWith("image")) {
      return (
        <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", width: "100%" }}>
          <img
            src={url}
            alt="Mensagem com imagem"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginTop: "5px" }}
          >
            {`Data: ${format(new Date(date), "dd/MM/yyyy HH:mm")}`}
          </Typography>
        </div>
      );
    }
    // Verifica se o tipo de mídia é application (para arquivos PDF, DOC, etc.)
    else if (type.startsWith("application")) {
      // Verifica se .pdf ou .doc está presente no body (não só na URL)
      const bodyLowerCase = body?.toLowerCase();
      const isPdf = bodyLowerCase?.includes(".pdf");
      const isDoc = bodyLowerCase?.includes(".doc");

      let iconColor = "gray"; // Cor padrão (cinza)
      let Icon = Description; // Ícone padrão de documento

      if (isPdf) {
        iconColor = "red"; // Cor vermelha para PDFs
        Icon = PictureAsPdf; // Ícone de PDF
      } else if (isDoc) {
        iconColor = "blue"; // Cor azul para DOC/DOCX
      }

      return (
        <div style={{ marginTop: "10px", display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{
              padding: "8px",
              borderRadius: "4px",
              marginRight: "10px",
            }}
          >
            <Icon style={{ color: iconColor }} /> {/* Ícone do arquivo */}
          </div>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginTop: "5px" }}
            >
              {body}
            </Typography>
            {`Data: ${format(new Date(date), "dd/MM/yyyy HH:mm")}`}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/timeline/messages/${ticketId}`, {
        params: {
          pageNumber,
          ticketTrakingId,
          untilDate,
          markAsRead: !false // DEVERIA SER FALSE
        },
      });

      setMessages(response.data.messages || []);
    } catch (err) {
      setError("Erro ao carregar mensagens.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticketId) {
      fetchMessages();
    }
  }, [ticketId, pageNumber, ticketTrakingId, untilDate]);

  return (
    <div style={modalStyles} onClick={onClose}>
      <div
        className={classes.modalContentStyles}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <List>
            {messages.length > 0 ? (
              messages.map((msg) => (
                <ListItem
                  key={msg.id}
                  divider
                  style={msg.fromMe ? messageFromMeStyles : messageFromOthersStyles}
                >
                  {(msg.mediaType === "audio" || msg.mediaType === "image" || msg.mediaType === "application")
                    ? renderFile(msg.mediaUrl, msg.mediaType, msg.id, msg.createdAt, msg.body)
                    : (
                      <>
                        <Typography>{msg.body || "Mensagem sem conteúdo"}</Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ marginTop: "5px" }}
                        >
                          {`Data: ${format(new Date(msg.createdAt), "dd/MM/yyyy HH:mm")}`}
                        </Typography>
                      </>
                    )}
                </ListItem>
              ))
            ) : (
              <Typography>Nenhuma mensagem encontrada.</Typography>
            )}
          </List>
        )}
      </div>
    </div>
  );
};

export default SpyTimelineModal;
