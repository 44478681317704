
import React from "react";
import { CheckCircle, HourglassEmpty, AssignmentTurnedIn, Shuffle, PersonAdd } from "@material-ui/icons";
import { PhoneMissed, Phone } from "@material-ui/icons";

const renderActionIcon = (actionType, classes) => {
    switch (actionType) {
        case "Mudança de status":
            return <AssignmentTurnedIn className={classes.icon} style={{ color: 'blue' }} />;
        case "Finalização do ticket":
            return <CheckCircle className={classes.icon} style={{ color: 'green' }} />;
        case "Início do acompanhamento":
            return <HourglassEmpty className={classes.icon} style={{ color: 'orange' }} />;
        case "Troca de fila":
            return <Shuffle className={classes.icon} style={{ color: 'purple' }} />;
        case "Troca de responsável":
            return <PersonAdd className={classes.icon} style={{ color: 'yellow' }} />;
        case "Chamada rejeitada":
            return <PhoneMissed className={classes.icon} style={{ color: "red" }} />;
        case "Chamada aceita":
            return <Phone className={classes.icon} style={{ color: "green" }} />;
        case "Contato indisponível": {
            return <Phone className={classes.icon} style={{ color: "pink" }} />;
        }
        default:
            return null;
    }
};

export default renderActionIcon;