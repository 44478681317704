import React, { useState, useEffect, useContext, useRef } from 'react';
import api from '../../services/api'; // Ajuste conforme o seu projeto
import {
  Table, TableBody, TableCell, TableHead, TableRow,
  Button, TextField, MenuItem, Grid, Paper, Typography,
  makeStyles,
  useTheme,
  Box
} from '@material-ui/core';
import { AuthContext } from '../../context/Auth/AuthContext'; // Pega o usuário logado do contexto
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Tooltip } from '@material-ui/core';
import * as XLSX from 'xlsx';
import toastError from '../../errors/toastError';
import Pagination from "@material-ui/lab/Pagination";
import { SingleUserFilter } from '../SingleUserFilter';
import { SingleWhatsappFilter } from '../SingleWhatsappFilter';

interface Call {
  id: number;
  callId: string;
  wppId: string;
  userName: string;
  companyId: number;
  createdAt: string;
  contactName: string;
  contactNumber: string;
  status: any,
  statusCode: any,
  ticketId: number;
}

interface SimpleCompany {
  id: number;
  name: string;
}
const useStyles = makeStyles((theme: any) => ({
  paper:
  {
    "& h6, & p, & label, & div": {
      fontSize: "14px",
      fontFamily: "Poppins"
    },
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    border: "2px solid red",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.palette.options,
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.fontecor,
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  boxContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  boxContainerNoFlex: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  tableStyles: {
    overflow: "auto",
    ...theme.scrollbarStyles,
    "& th, p, & span,& td": {
      fontFamily: "Poppins",
      fontWeight: '500 !important',
      fontSize: "14px",
      color: theme.palette.primary.mainReverseByWhite

    },

    "& td": {
      color: theme.palette.blackOrWhite
    },
  },
}));
const CallsByCompany = () => {
  const { user } = useContext(AuthContext); // Pega o usuário logado do AuthContext
  const [calls, setCalls] = useState<Call[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [availableCompanies, setAvailableCompanies] = useState<SimpleCompany[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(user.super ? '' : user.companyId.toString()); // Filtro de empresa
  const [startDate, setStartDate] = useState<string>(''); // Filtro por data de início
  const [endDate, setEndDate] = useState<string>(''); // Filtro por data de fim
  const [audioUrls, setAudioUrls] = useState<{ [key: string]: string }>({}); // Guarda os URLs dos áudios
  const theme: any = useTheme();
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage]: any = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedWhatsappName, setSelectedWhatsappName] = useState("");
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
  const classes: any = useStyles();

  const handlePlay = (callId: string) => {
    Object.keys(audioRefs.current).forEach((otherCallId) => {
      const audio = audioRefs.current[otherCallId];
      if (audio && !audio.paused && otherCallId !== callId) {
        audio.pause();
      }
    });
    setCurrentlyPlaying(callId);
  };

  useEffect(() => {
    Object.keys(audioUrls).forEach((callId) => {
      const audioElement = audioRefs.current[callId];
      if (audioElement) {
        audioElement.onpause = () => {
          if (currentlyPlaying === callId) {
            setCurrentlyPlaying(null);
          }
        };
      }
    });
  }, [audioUrls, currentlyPlaying]);

  useEffect(() => {
    if (user.super) {
      const fetchCompanies = async () => {
        try {
          const response = await api.get('/api/messages/companies');
          setAvailableCompanies(response.data);
        } catch (err) {
          console.error('Erro ao buscar empresas', err);
        }
      };
      fetchCompanies();
    }
  }, [user.super]);

  useEffect(() => {
    if (!currentPage) {
      return
    }
    const handleChangePage = async () => {
      try {
        const params = {
          startDate,
          endDate,
          companyId: selectedCompanyId,
          currentPage: currentPage,
          userName: selectedWhatsappName,
          wppId: selectedWhatsapp,
          contactNumber: phoneNumber
        };
        const response = await api.get('/api/calls', { params });
        setCalls(response?.data.data);
        setCount(response?.data?.meta?.totalItems)
        setTotalPages((response?.data?.meta?.totalPages));
        const urls: { [key: string]: string } = {};
        for (const call of response.data.data) {
          const audioResponse = await api.get(`/api/calls/${call.callId}`, {
            params: { companyId: call.companyId.toString() },
          });
          const finalUrl = `${process.env.REACT_APP_BACKEND_URL}${audioResponse.data.audioUrl}`;
          urls[call.callId] = finalUrl;
        }
        setAudioUrls(urls);
      } catch (err) {
        setError('Erro ao buscar chamadas');
        console.error('Erro na requisição:', err);
      } finally {
        setLoading(false);
      }
    };

    handleChangePage();
  }, [currentPage]);

  const handleFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const params = {
        startDate,
        endDate,
        companyId: selectedCompanyId,
        pageNumber: 1,
        userName: selectedWhatsappName,
        wppId: selectedWhatsapp,
        contactNumber: phoneNumber
      };
      const response = await api.get('/api/calls', { params });
      setCurrentPage(1);
      setCalls(response?.data.data)
      setTotalPages((response?.data?.meta?.totalPages));
      const urls: { [key: string]: string } = {};
      for (const call of response.data.data) {
        const audioResponse = await api.get(`/api/calls/${call.callId}`, {
          params: { companyId: call.companyId.toString() },
        });
        const finalUrl = `${process.env.REACT_APP_BACKEND_URL}${audioResponse.data.audioUrl}`;
        urls[call.callId] = finalUrl;
      }
      setAudioUrls(urls);
    } catch (err) {
      setError('Erro ao buscar chamadas');
      console.error('Erro na requisição:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadAudio = async (callId: string, companyId: string) => {
    try {
      const { data } = await api.get(`/api/calls/${callId}`, { params: { companyId } });

      if (!data.audioUrl) {
        console.error('URL de áudio não disponível.');
        return;
      }

      const audioUrl = `${process.env.REACT_APP_BACKEND_URL}${data.audioUrl}`;

      const response = await fetch(audioUrl);
      if (!response.ok) {
        throw new Error(`Erro ao obter o áudio: ${response.statusText}`);
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${callId}.mp3`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o áudio:', error);
    }
  };

  const exportarGridParaExcel = async () => {
    setLoading(true);
    try {
      const params = {
        startDate,
        endDate,
        companyId: selectedCompanyId,
        pageSize: count,
        selectedUser: selectedWhatsappName,
        wppId: selectedWhatsapp,
        contactNumber: phoneNumber
      };

      const response = await api.get('/api/calls', { params });
      const data = response?.data?.data;
      const ticketsData = data.map((callFound: any) => ({
        "id": callFound?.id,
        "userName": callFound?.userName,
        "callId": callFound?.callId,
        "ticketId": callFound?.ticketId,
        "contactNumber": callFound?.contactNumber,
        "contactName": callFound?.contactName,
        "statusCode": callFound?.statusCode,
        "status": callFound?.status,
        "createdAt": callFound?.createdAt,
        "updatedAt": callFound?.updatedAt,
        "audioAvailable": callFound?.audioAvailable
      }));
      const ws = XLSX.utils.json_to_sheet(ticketsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'RelatorioDeChamadas');
      XLSX.writeFile(wb, 'relatorio-de-chamadas.xlsx');
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  const handleSelectedUser = (selecteds: any) => {
    setSelectedUser(selecteds?.id);
    setSelectedWhatsappName(selecteds?.name);
  };

  const handleSelectedWhatsapp = (selecteds: any) => {
    setSelectedWhatsapp(selecteds?.id);
  };

  const handleChange = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div className={classes.paper}>
      <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", marginBottom: "1rem" }}>
        <h6 style={{ marginBottom: "1rem", fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite }}>
          Chamadas por Empresa
        </h6>
        {calls?.length > 0 && (
          <Button className={classes.btnStyles} onClick={exportarGridParaExcel} >
            Baixar relatório
          </Button>
        )}
      </div>

      <Paper elevation={3} style={{ padding: '1rem', marginBottom: '2rem', borderRadius: "8px", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, overflow: "auto" }}>
        <form onSubmit={handleFilter}>
          <Grid container spacing={2} alignItems="center">
            {/* Se for superadmin, exibe o campo para selecionar empresa */}
            {user.super && (
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Empresa"
                  value={selectedCompanyId}
                  onChange={(e) => setSelectedCompanyId(e.target.value)}
                  fullWidth
                >
                  {availableCompanies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {!user.super && (
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Empresa: {user.company.name}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Fim"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button className={classes.btnStyles} type="submit" fullWidth>
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Box style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem" }}>
            <TextField
              label="Número do contato"
              variant="outlined"
              value={phoneNumber}
              onChange={handleChange}
              placeholder="Enter phone number"
              fullWidth
              size="small"
            />
            <SingleUserFilter onFiltered={handleSelectedUser} initialUser={selectedUser} selectedUser={selectedUser} />
            <SingleWhatsappFilter onFiltered={handleSelectedWhatsapp} initialWhatsapp={selectedWhatsapp} />
          </Box>
        </form>
      </Paper>

      {calls.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          Nenhuma chamada encontrada.
        </Typography>
      ) : (
        <Paper elevation={3} className={classes.tableStyles} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">ID da Chamada</TableCell>
                <TableCell align="center">Nome do Usuário</TableCell>
                <TableCell align="center">Nome do Destinatário</TableCell>
                <TableCell align="center">Número do Destinatário</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Ticket ID</TableCell> {/* Exibindo o ticketId */}
                <TableCell align="center">Data de Criação</TableCell>
                <TableCell align="center">Áudio</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableStyles}>
              {!loading ? calls.map((call) => (
                <TableRow key={call.id}>
                  <TableCell align="center">{call.callId}</TableCell>
                  <TableCell align="center">{call.userName}</TableCell>
                  <TableCell align="center">{call?.contactName}</TableCell>
                  <TableCell align="center">{call.contactNumber}</TableCell>
                  <Tooltip title={call?.status}><TableCell style={{ fontFamily: "Poppins", fontWeight: "500", color: call?.statusCode === 200 ? "green" : "red" }} align="center">{call?.statusCode}</TableCell></Tooltip>
                  <TableCell align="center">{call.ticketId}</TableCell> {/* Exibindo o ticketId */}
                  <TableCell align="center">
                    {format(new Date(call.createdAt), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
                  </TableCell>
                  <TableCell align="center">
                    {audioUrls[call.callId] && (
                      <audio
                        ref={(el: any) => (audioRefs.current[call.callId] = el)}
                        controls
                        style={{ width: '250px', height: '30px' }}
                        onPlay={() => handlePlay(call.callId)} // Chama handlePlay ao iniciar o áudio
                      >
                        <source src={audioUrls[call.callId]} type="audio/mp3" />
                        Seu navegador não suporta o elemento de áudio.
                      </audio>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      className={classes.btn}
                      onClick={() => handleDownloadAudio(call.callId, call.companyId.toString())}
                    >
                      Baixar
                    </Button>
                  </TableCell>
                </TableRow>
              )) : "Carregando..."}
            </TableBody>
          </Table>

          {/* Paginação */}
          <div className={classes.pagination}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
            />
          </div>
        </Paper>
      )}
    </div>
  );
};

export default CallsByCompany;
