import * as XLSX from "xlsx";

const handleDownloadTemplate = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [
        { name: "Example Name", number: "123456789", email: "example@email.com" },
    ];
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    XLSX.writeFile(workbook, "template.xlsx");
};

export default handleDownloadTemplate;
