export const daysTranslation = {
    segunda: "Monday",
    terça: "Tuesday",
    quarta: "Wednesday",
    quinta: "Thursday",
    sexta: "Friday",
    sábado: "Saturday",
    domingo: "Sunday",
};

export const scheduleInitialState = {
    segunda: { inicio: "09:00", fim: "19:00" },
    terça: { inicio: "09:00", fim: "19:00" },
    quarta: { inicio: "09:00", fim: "19:00" },
    quinta: { inicio: "09:00", fim: "19:00" },
    sexta: { inicio: "09:00", fim: "19:00" },
    sábado: { inicio: "09:00", fim: "19:00" },
    domingo: { inicio: "09:00", fim: "19:00" },
}