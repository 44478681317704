import { toast } from "react-toastify";
import api from "../../../../services/api";
import handleCloseModal from "./handleCloseModal";

const handleSave = async (selectedContact, setBlacklist, blacklist, setOpenModal, setSelectedContact) => {
    try {
        const response = await api.put(`/blacklist/${selectedContact.id}`, { contact: selectedContact });
        toast.success("Item updated successfully.");
        setBlacklist(
            blacklist.map((item) =>
                item.id === selectedContact.id ? {...selectedContact, number: response.data.contact.number.toString().replace(/\s+/g, '').replace(/\D/g, '')} : item
            )
        );
        handleCloseModal(setOpenModal, setSelectedContact);
    } catch (error) {
        toast.error("Failed to update item.");
    }
};

export default handleSave;