import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginTop: 8,
  },
  uploadInput: {
    display: "none",
  },
  btns: {
    margin: 15,
  },
  label: {
    padding: 18,
    width: "100%",
    textTransform: 'uppercase',
    display: 'block',
    marginTop: 10,
    marginBottom: "1rem",
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 8,
  },
  modalTitle: {
    fontFamily: "Poppins",
    textAlign: "center",
    color: theme.palette.primary.mainReverseByWhite,
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      background: "transparent",
    },
  },
  answersLabel: {
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "center"
  },

  concluedStyles: {
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "center"
  }
}));

export default useStyles;
