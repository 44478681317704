import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    buttonHover: {
        backgroundColor: "#0056b3",
    },
    popup: {
        position: "fixed",
        bottom: "100px",
        right: "2rem",
        width: "320px",
        minHeight: "430px",
        background: "linear-gradient(to right, #1f1f1f99, #1e1f1f99)",
        borderRadius: "38px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
    },
    screen: {
        padding: "20px",
        minHeight: "430px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    },
    displayContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    display: {
        paddingTop: "1rem",
        width: "100%",
        height: "50px",
        backgroundColor: "transparent",
        border: "none",
        color: "white",
        borderRadius: "5px",
        marginBottom: "20px",
        fontSize: "20px",
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        padding: "10px",
        boxSizing: "border-box",
    },
    clearButton: {
        position: "absolute",
        top: "50%",
        right: "0",
        transform: "translateY(-50%)",
        backgroundColor: "transparent",
        border: "none",
        color: "white",
        cursor: "pointer",
        padding: "5px",
    },
    keypad: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
    },
    key: {
        width: "60px",
        height: "60px",
        backgroundColor: "transparent",
        color: "white",
        border: "1px solid white",
        borderRadius: "50%",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    actionButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        textAlign: "center",
        marginTop: "16px",
    },
    callButton: {
        padding: "10px",
        backgroundColor: "#28a745",
        color: "white",
        border: "none",
        borderRadius: "50%",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    avatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        backgroundColor: theme.palette.grey[300],
        margin: "0 auto 16px",
    },
    avatarIcon: {
        fontSize: "50px",
        color: theme.palette.text.primary,
    },
    callingScreen: {
        textAlign: "center",
        padding: "16px",
    },
    phoneNumber: {
        fontSize: "24px",
        fontFamily: "Poppins",
        color: "white",
        margin: "16px 0",
    },
    callDuration: {
        fontSize: "16px",
        fontFamily: "Poppins",

        fontWeight: "bold",
        color: "white",
    },
    callButtonRed: {
        backgroundColor: theme.palette.error.main,
        border: "none",
        padding: "10px",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#fff",
    },
    button: {
        padding: "20px 20px",
        background: theme.palette.primary.mainGradient,
        color: "white",
        border: "none",
        position: "fixed",
        borderRadius: "100%",
        cursor: "pointer",
        bottom: "1rem",
        right: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    incomingCall: {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    },

    displayInput: {
        width: "80%",
        padding: "10px",
        color: "white",
        fontSize: "16px",
        background: "transparent",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
        outline: "none",
        "&:focus": {
            borderColor: "white",
            boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)",
        }
    },
    contactModalButton: {
        padding: "10px 10px",
        background: "transparent",
        color: "white",
        border: "2px solid white",
        position: "fixed",
        borderRadius: "100%",
        cursor: "pointer",
        bottom: "1.5rem",
        left: "5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    contactModalButton2: {
        padding: "10px 10px",
        background: "transparent",
        color: "white",
        border: "2px solid white",
        position: "fixed",
        borderRadius: "100%",
        cursor: "pointer",
        bottom: "1.5rem",
        right: "5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    contactsModal: {
        position: "absolute",
        bottom: "30%",
        right: "10%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        borderRadius: "12px",
        padding: "1.5rem",
        background: "linear-gradient(to right, #1f1f1f, #2a2a2a)",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
        color: "white",
        fontFamily: "'Poppins', sans-serif",
        width: "300px",
        
    },
    modalHeader: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "1rem",
    },
    searchInput: {
        width: "100%",
        padding: "0.5rem",
        marginBottom: "1rem",
        borderRadius: "8px",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        color: "#333",
    },
    loadingText: {
        textAlign: "center",
        fontStyle: "italic",
    },
    contactList: {
        listStyleType: "none",
        padding: "0",
        margin: "0",
        maxHeight: "400px",
        overflow: "auto",
        ...theme.scrollbarStyles
    },
    contactItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 0",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    },
    contactDetails: {
        display: "flex",
        flexDirection: "column",
    },
    contactName: {
        fontSize: "1rem",
        fontWeight: "bold",
    },
    contactPhone: {
        fontSize: "0.9rem",
        color: "#aaa",
    },

}));

export default useStyles;