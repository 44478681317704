const toggleOrder = (
    setIsAscending,
    isAscending,
    handleTicketClick,
    setHideLoadMore,
    setCurrentPage,
    selectedTicket
) => {
    const newOrder = isAscending ? 'desc' : 'asc';
    setIsAscending(!isAscending);
    setCurrentPage(1);
    setHideLoadMore(false);
    handleTicketClick(selectedTicket?.data[0]?.ticketId, 1, 10, newOrder);
};

export default toggleOrder;
