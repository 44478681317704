import React from "react";
import {
  Button,
  TextField,
  Grid,
  Collapse,
} from "@material-ui/core";

const EveryHourUpdaterComponent = ({
  openAll,
  classes,
  newStartTime,
  setNewStartTime,
  newEndTime,
  setNewEndTime,
  handleChangeAll,
  setSchedule
}) => {
  return (
    <Collapse in={openAll}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <span className={classes.dayLabel}>Alterar todos os dias</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Início"
            type="time"
            value={newStartTime}
            onChange={(e) => setNewStartTime(e.target.value)}
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: 300,
              min: "00:00",
              max: "19:00",
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Fim"
            type="time"
            value={newEndTime}
            onChange={(e) => setNewEndTime(e.target.value)}
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: 300,
              min: "00:00",
              max: "19:00",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleChangeAll(setSchedule, newStartTime, newEndTime)}
            className={classes.button}
            fullWidth
          >
            Alterar todos os horários
          </Button>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default EveryHourUpdaterComponent;
