import React from "react";
import GroupIcon from "@material-ui/icons/Group";
import { useTheme } from "@material-ui/core";

const OnlineAttendants = ({ attendants, classes }) => {
  // Define como 0 caso attendants seja indefinido, nulo ou não seja um número
  const onlineCount = !isNaN(Number(attendants)) && attendants !== undefined && attendants !== null
    ? Number(attendants)
    : 0;
  const theme = useTheme();
  return (
    <div className={`cmp-dashboard__raw-data-item--first ${classes.dashBoardFirstItem}`}>
      <div >
        <h2 style={{color: theme.palette.blackOrWhite, fontSize: "16px", fontWeight: "400"}}>Atendentes online</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <GroupIcon />
        <span>{onlineCount}</span>
        <div className="cmp-dashboard__online-circle"></div>
      </div>
    </div>
  );
};

export default OnlineAttendants;
