import React, { useContext, useState } from 'react';
import { Button, Grid, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from "@material-ui/core";
import ColorModeContext from '../../layout/themeContext';
import api from '../../services/api';
import { AuthContext } from '../../context/Auth/AuthContext';
import transformToEnvVars from '../../utils/envToVars';
import themeDefault from '../../static/themeDefault';
import useStyles from './style';

const ColorSettings = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { colorMode } = useContext(ColorModeContext);
  const storedTheme = JSON.parse(localStorage.getItem("pageTheme")) || themeDefault;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [simulatedTheme, setSimulatedTheme] = useState(false);

  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const lightTheme = Object.keys(storedTheme)
    ?.filter((key) => key.startsWith("REACT_APP_LIGHT_"))
    .reduce((acc, key) => {
      acc[key] = storedTheme[key];
      return acc;
    }, {});

  const darkTheme = Object.keys(storedTheme)
    ?.filter((key) => key.startsWith("REACT_APP_DARK_"))
    .reduce((acc, key) => {
      acc[key] = storedTheme[key];
      return acc;
    }, {});

  const [lightColors, setLightColors] = useState({
    REACT_APP_LIGHT_MAIN_COLOR: lightTheme.REACT_APP_LIGHT_MAIN_COLOR || '#EF7A59',
    REACT_APP_LIGHT_SECOND_COLOR: lightTheme.REACT_APP_LIGHT_SECOND_COLOR || '#ED6956',
    REACT_APP_LIGHT_THIRD_COLOR: lightTheme.REACT_APP_LIGHT_THIRD_COLOR || '#080929',
    REACT_APP_LIGHT_TEXT_COLOR: lightTheme.REACT_APP_LIGHT_TEXT_COLOR || '#26254A90',
    REACT_APP_LIGHT_LINKS_COLOR: lightTheme.REACT_APP_LIGHT_LINKS_COLOR || '#003366',
    REACT_APP_LIGHT_MAIN_GRADIENT: lightTheme.REACT_APP_LIGHT_MAIN_GRADIENT || `linear-gradient(to right, #ED6956 , #EF7A59)`,
    REACT_APP_LIGHT_SECOND_GRADIENT: lightTheme.REACT_APP_LIGHT_SECOND_GRADIENT || `linear-gradient(to right, #ED6956 , #EF7A59)`,
    REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN: lightTheme.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN || '#FFFFFF',
    REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE: lightTheme.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE || '#ED6956',
  });

  const [darkColors, setDarkColors] = useState({
    REACT_APP_DARK_MAIN_COLOR: darkTheme.REACT_APP_DARK_MAIN_COLOR || '#ED6956',
    REACT_APP_DARK_SECOND_COLOR: darkTheme.REACT_APP_DARK_SECOND_COLOR || '#FFFFFF',
    REACT_APP_DARK_THIRD_COLOR: darkTheme.REACT_APP_DARK_THIRD_COLOR || '#1976D2',
    REACT_APP_DARK_TEXT_COLOR: darkTheme.REACT_APP_DARK_TEXT_COLOR || '#FFFFFF',
    REACT_APP_DARK_LINKS_COLOR: darkTheme.REACT_APP_DARK_LINKS_COLOR || '#1976D2',
    REACT_APP_DARK_MAIN_GRADIENT: darkTheme.REACT_APP_DARK_MAIN_GRADIENT || `linear-gradient(to right, #ED6956 , #EF7A59)`,
    REACT_APP_DARK_SECOND_GRADIENT: darkTheme.REACT_APP_DARK_SECOND_GRADIENT || `linear-gradient(to right, #ED6956 , #EF7A59)`,
    REACT_APP_DARK_REVERSE_WHITE_BY_MAIN: darkTheme.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN || 'linear-gradient(to right,#ED6956, #EF7A59)',
    REACT_APP_DARK_MAIN_REVERSE_BY_WHITE: darkTheme.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE || '#FFFFFF',
  });

  const labels = {
    REACT_APP_LIGHT_MAIN_COLOR: 'Cor Principal',
    REACT_APP_LIGHT_SECOND_COLOR: 'Segunda Cor',
    REACT_APP_LIGHT_THIRD_COLOR: 'Terceira Cor',
    REACT_APP_LIGHT_TEXT_COLOR: 'Cor do Texto',
    REACT_APP_LIGHT_LINKS_COLOR: 'Cor dos Links',
    REACT_APP_LIGHT_MAIN_GRADIENT: 'Gradiente Principal',
    REACT_APP_LIGHT_SECOND_GRADIENT: 'Segundo Gradiente',
    REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN: 'Background escuro',
    REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE: 'Texto com fundo branco',
    REACT_APP_DARK_MAIN_COLOR: 'Cor Principal',
    REACT_APP_DARK_SECOND_COLOR: 'Segunda Cor',
    REACT_APP_DARK_THIRD_COLOR: 'Terceira Cor',
    REACT_APP_DARK_TEXT_COLOR: 'Cor do Texto',
    REACT_APP_DARK_LINKS_COLOR: 'Cor dos Links',
    REACT_APP_DARK_MAIN_GRADIENT: 'Gradiente Principal',
    REACT_APP_DARK_SECOND_GRADIENT: 'Segundo Gradiente',
    REACT_APP_DARK_REVERSE_WHITE_BY_MAIN: 'Texto claro',
    REACT_APP_DARK_MAIN_REVERSE_BY_WHITE: 'Texto com fundo escuro',
  };

  const handleSimulateTheme = () => {
    const newTheme = { ...lightColors, ...darkColors };
    colorMode.setPageTheme(newTheme);
    localStorage.setItem("pageTheme", JSON.stringify(newTheme));
    setSimulatedTheme(true);
  };

  const handleRevertTheme = async () => {
    const { data } = await api.get(`/themes/${user.companyId}`);
    if (!data) {
      localStorage.setItem("pageTheme", JSON.stringify(themeDefault))
      colorMode.setPageTheme(themeDefault);
    } else {
      localStorage.setItem("pageTheme", JSON.stringify(transformToEnvVars(data)))
      colorMode.setPageTheme(transformToEnvVars(data));
    }
    setSimulatedTheme(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const dataToSend = {
      id: 5,
      companyId: user.companyId,
      main_color: lightColors.REACT_APP_LIGHT_MAIN_COLOR,
      second_color: lightColors.REACT_APP_LIGHT_SECOND_COLOR,
      third_color: lightColors.REACT_APP_LIGHT_THIRD_COLOR,
      text_color: lightColors.REACT_APP_LIGHT_TEXT_COLOR,
      links_color: lightColors.REACT_APP_LIGHT_LINKS_COLOR,
      main_gradient: lightColors.REACT_APP_LIGHT_MAIN_GRADIENT,
      second_gradient: lightColors.REACT_APP_LIGHT_SECOND_GRADIENT,
      reverse_white_by_main: lightColors.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN,
      main_reverse_by_white: lightColors.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE,
      dark_main_color: darkColors.REACT_APP_DARK_MAIN_COLOR,
      dark_second_color: darkColors.REACT_APP_DARK_SECOND_COLOR,
      dark_third_color: darkColors.REACT_APP_DARK_THIRD_COLOR,
      dark_text_color: darkColors.REACT_APP_DARK_TEXT_COLOR,
      dark_links_color: darkColors.REACT_APP_DARK_LINKS_COLOR,
      dark_main_gradient: darkColors.REACT_APP_DARK_MAIN_GRADIENT,
      dark_second_gradient: darkColors.REACT_APP_DARK_SECOND_GRADIENT,
      dark_reverse_white_by_main: darkColors.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN,
      dark_main_reverse_by_white: darkColors.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE,
      logo: "http://example.com/logo.png",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    try {
      const newTheme = { ...lightColors, ...darkColors };
      colorMode.setPageTheme(newTheme);
      localStorage.setItem("pageTheme", JSON.stringify(newTheme))
    } catch (error) {
      console.error("Error saving theme:", error);
    } finally {
      setLoading(false);
      handleCloseConfirm();
    }
  };


  const handleColorChange = (color, key, isDarkMode, colorIndex) => {
    if (isDarkMode) {
      const updatedColors = { ...darkColors };
      if (key.includes('GRADIENT')) {
        const gradientColors = updatedColors[key].match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ');
        gradientColors[colorIndex - 1] = color;
        updatedColors[key] = `linear-gradient(to right, ${gradientColors.join(', ')})`;
      } else {
        updatedColors[key] = color;
      }
      setDarkColors(updatedColors);
    } else {
      const updatedColors = { ...lightColors };
      if (key === 'REACT_APP_LIGHT_MAIN_COLOR') {
        updatedColors[key] = color;
        updatedColors.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE = color;
      } else if (key === 'REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE') {
        updatedColors[key] = color;
        updatedColors.REACT_APP_LIGHT_MAIN_COLOR = color;
      } else if (key.includes('GRADIENT')) {
        const gradientColors = updatedColors[key].match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ');
        gradientColors[colorIndex - 1] = color;
        updatedColors[key] = `linear-gradient(to right, ${gradientColors.join(', ')})`;
      } else {
        updatedColors[key] = color;
      }
      setLightColors(updatedColors);
    }
  };

  const renderGradientPreview = (gradient) => ({
    background: gradient,
    height: '50px',
    borderRadius: '5px',
    margin: '10px 0',
  });

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography style={{ marginBottom: "1rem", fontSize: "14px" }} variant="h6" className={classes.item}>Modo Claro</Typography>
          <Grid container spacing={1}>
            {/* Cores sólidas - Modo Claro */}
            {Object.entries(lightColors)?.map(([key, value]) => (
              !key.includes('GRADIENT') && (
                <Grid item xs={3} key={key}>
                  <Typography className={classes.item}>{labels[key]}</Typography>
                  <input
                    type="color"
                    value={value}
                    onChange={(e) => handleColorChange(e.target.value, key, false)}
                  />
                </Grid>
              )
            ))}

            {/* Gradientes - Modo Claro */}
            {Object.entries(lightColors)?.map(([key, value]) => (
              key.includes('GRADIENT') && (
                <Grid item xs={12} key={key}>
                  <Typography className={classes.item}>{labels[key]}</Typography>
                  <input
                    type="color"
                    value={value.match(/linear-gradient\(to right, ([^)]+)\)/) ? value.match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ')[0] : "#000000"}
                    onChange={(e) => handleColorChange(e.target.value, key, false, 1)}
                  />
                  <input
                    type="color"
                    value={value.match(/linear-gradient\(to right, ([^)]+)\)/) ? value.match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ')[1] : "#000000"}
                    onChange={(e) => handleColorChange(e.target.value, key, false, 2)}
                  />
                  <div style={renderGradientPreview(value)} />
                </Grid>
              )
            ))}
          </Grid>
        </div>
      </Grid>

      {/* Modo Escuro */}
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant="h6" className={classes.item} style={{ marginBottom: "1rem", fontSize: "14px" }}>Modo Escuro</Typography>
          <Grid container spacing={1}>
            {/* Cores sólidas - Modo Escuro */}
            {Object.entries(darkColors)?.map(([key, value]) => (
              !key.includes('GRADIENT') && (
                <Grid item xs={3} key={key}>
                  <Typography className={classes.item}>{labels[key]}</Typography>
                  <input
                    type="color"
                    value={value}
                    onChange={(e) => handleColorChange(e.target.value, key, true)}
                  />
                </Grid>
              )
            ))}

            {/* Gradientes - Modo Escuro */}
            {Object.entries(darkColors)?.map(([key, value]) => (
              key.includes('GRADIENT') && (
                <Grid item xs={12} key={key}>
                  <Typography className={classes.item}>{labels[key]}</Typography>
                  <input
                    type="color"
                    value={value.match(/linear-gradient\(to right, ([^)]+)\)/) ? value.match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ')[0] : "#000000"}
                    onChange={(e) => handleColorChange(e.target.value, key, true, 1)}
                  />
                  <input
                    type="color"
                    value={value.match(/linear-gradient\(to right, ([^)]+)\)/) ? value.match(/linear-gradient\(to right, ([^)]+)\)/)[1].split(', ')[1] : "#000000"}
                    onChange={(e) => handleColorChange(e.target.value, key, true, 2)}
                  />
                  <div style={renderGradientPreview(value)} />
                </Grid>
              )
            ))}
          </Grid>
        </div>
      </Grid>


      {/* Modal de Confirmação */}
      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Confirmar Salvamento</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza de que deseja salvar as configurações de cores?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">Cancelar</Button>
          <Button onClick={handleSave} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Confirmar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={simulatedTheme ? handleRevertTheme : handleSimulateTheme}
        >
          {simulatedTheme ? 'Reverter' : 'Simular'}
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleOpenConfirm}
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default ColorSettings;
