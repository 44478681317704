import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  modalTitle: {
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite,
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      background: "transparent",
    },
  },
}));

export default useStyles;