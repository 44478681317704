import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        color: theme.palette.primary.mainReverseByWhite,
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 1300,
        padding: '20px',
    },
    timelineWrapper: {
        display: 'flex',
        position: 'relative',
        flexDirection: "column",
        padding: '20px',
        width: '80%',
        height: '35vh',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        ...theme.scrollbarStyles
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '20px 10px',
    },
    actionType: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    actionTypeUserNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    actionTypeUserName: {
        fontWeight: 'bold',
    },

    
    avatarSmall: {
        width: '24px',
        height: '24px',
    },
    actionDetails: {
        textAlign: 'center',
        fontSize: '14px',
        color: theme.palette.text.secondary,
        marginTop: '5rem',
    },
    actionLine: {
        height: '2px',
        backgroundColor: theme.palette.grey[300],
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        zIndex: 0,  // Certifica-se de que a linha fique atrás do ícone
    },
    actionCircle: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        border: '3px solid white',
        position: 'relative',
        zIndex: 1,
    },
    icon: {
        fontSize: '24px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    },
    
    timelineDirection: {
        display: "flex",
        overflowX: "auto",
        ...theme.scrollbarStyles
    },

    timelineTicketHeader: {
        display: "flex",
        gap: "1rem",
        zIndex: "10",
        width: "100%",
        justifyContent: "space-between"
    },

    btnStyles: {
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        color: theme.palette.primary.mainReverseByWhite,
        background: "transparent",
        "&:hover": {
          background: "transparent"
        }
      },

      ticketButtons: {
        display: "flex",
        gap: "1rem"
      },
    
      audioButton: {
        borderRadius: "100%",
        margin: "0.5rem 0",
        minWidth: "30px",
        width: "30px"
      }
}));

export default useStyles;