import { toast } from "react-toastify";
import api from "../../../../services/api";

const handleDelete = async (id, setBlacklist, blacklist) => {
    try {
        await api.delete(`/blacklist/${id}`);
        toast.success("Deletado com sucesso!");
        setBlacklist(blacklist.filter((item) => item.id !== id));
    } catch (error) {
        toast.error("Falha ao deletar.");
    }
};

export default handleDelete;