import { toast } from "react-toastify";
import api from "../../../../services/api";
import handleCloseModal from "./handleCloseModal";

const handleCreate = async (newContact, setBlacklist, blacklist, setOpenModal, setSelectedContact) => {
    try {
        const response = await api.post("/blacklist/contact", { contact: newContact });
        toast.success("Contato criado com sucesso");
        const createdContact = {
            ...response.data.contact,
            number: response.data.contact.number.toString().replace(/\s+/g, '').replace(/\D/g, '')
        };
        setBlacklist([createdContact, ...blacklist]);
        handleCloseModal(setOpenModal, setSelectedContact);
    } catch (error) {
        toast.error("Erro ao adicionar contato");
    }
};

export default handleCreate;
