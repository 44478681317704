import React from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import ButtonWithSpinner from "../../components/ButtonWithSpinner";

const DashboardFilter = ({
    filterType,
    dateFrom,
    dateTo,
    setDateFrom,
    classes,
    loading,
    setDateTo,
    period,
    handleChangePeriod,
    handleChangeFilterType,
    fetchData,
    setLoading,
    selectedGroupId,
    setGroupsFound,
    find,
    user,
    setCounters,
    setAllTicketsCount,
    setLastTickets,
    setAttendants,
    groupList,
    page,
    searchTerm,
    setMessages,
    setTotalMessages,
    selectedUser,
    setPrivateHeatmapFound
}) => {

    function RenderFilters() {

        if (filterType === 1) {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            );
        } else {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">Período</InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={(e) => handleChangePeriod(e.target.value)}
                        >
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>Selecione o período desejado</FormHelperText>
                    </FormControl>
                </Grid>
            );
        }
    }

    return (
        <Grid container spacing={3} justifyContent="flex-end">
            <div className="cmp-dashboard__filter-container">
                <FormControl className={classes.selectContainer}>
                    <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
                    <Select
                        labelId="period-selector-label"
                        value={filterType}
                        onChange={(e) =>handleChangeFilterType(e.target.value)}
                    >
                        <MenuItem value={1}>Filtro por Data</MenuItem>
                    </Select>
                    <FormHelperText>Selecione o período desejado</FormHelperText>
                </FormControl>

                <RenderFilters />
            </div>

            <Grid item xs={12} className={classes.alignRight}>
                <ButtonWithSpinner
                    loading={loading}
                    onClick={() => fetchData(setLoading, dateFrom, dateTo, selectedGroupId, setGroupsFound, find, user, setCounters, setAllTicketsCount, setLastTickets, setAttendants, period, groupList, page, searchTerm, setMessages, setTotalMessages, selectedUser, setPrivateHeatmapFound)}
                    variant="contained"
                    className={classes.filterButton}
                >
                    Filtrar
                </ButtonWithSpinner>
            </Grid>

        </Grid>
    );
};

export default DashboardFilter;
 