import React from 'react';
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns';

const mapStatus = (status) => {
    switch (status) {
        case 'open':
            return 'Em atendimento';
        case 'pending':
            return 'Aguardando';
        case 'closed':
            return 'Fechado';
        default:
            return status;
    }
};

const renderActionDetails = (actionType, ticket) => {
    const userName = ticket.user?.name || 'Sem atendente vinculado';
    const formattedCreatedAt = format(new Date(ticket.createdAt), 'dd/MM/yyyy HH:mm'); // Formatação da data

    const renderCreatedAt = () => (
        <Typography variant="caption" color="textSecondary">
            Criado em: {formattedCreatedAt}
        </Typography>
    );

    switch (actionType) {
        case "Mudança de status":
            return (
                <>
                    <Typography variant="body2">
                        Status alterado para: {mapStatus(ticket.status)}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
        case "Finalização do ticket":
            return (
                <>
                    <Typography variant="body2">
                        Ticket finalizado em: {ticket.finishedAt ? format(new Date(ticket.finishedAt), 'dd/MM/yyyy HH:mm') : 'N/A'}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
        case "Início do acompanhamento":
            return (
                <>
                    <Typography variant="body2">
                        Acompanhamento iniciado em: {formattedCreatedAt}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
        case "Troca de fila":
            return (
                <>
                    <Typography variant="body2">
                        Fila alterada para: {ticket.queueName || 'Sem fila'}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
        case "Troca de responsável":
            return (
                <>
                    <Typography variant="body2">
                        Responsável alterado para: {userName}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
        default:
            return (
                <>
                    <Typography variant="body2">
                        Responsável: {userName}
                    </Typography>
                    {renderCreatedAt()}
                </>
            );
    }
};
export default renderActionDetails;
