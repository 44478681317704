import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import { Skeleton } from '@material-ui/lab';
import handleEdit from "../handles/handleEdit";
import handleDelete from "../handles/handleDelete";

const BlackListTable = ({
    classes,
    isLoading,
    searchTerm,
    setSearchTerm,
    blacklist,
    setCurrentPage,
    setSelectedContact,
    setOpenModal,
    theme,
    setBlacklist,
    totalPages,
    currentPage,
    setIsEdit
}) => {
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    return (
        <Paper className={classes.paper}>
            {isLoading ? (
                <>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item>
                            <TextField
                                className={classes.searchInput}
                                label={"Pesquisar"}
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Skeleton width="100px" /></TableCell>
                                <TableCell><Skeleton width="100px" /></TableCell>
                                <TableCell><Skeleton width="100px" /></TableCell>
                                <TableCell><Skeleton width="100px" /></TableCell>
                                <TableCell><Skeleton width="100px" /></TableCell>
                                <TableCell><Skeleton width="100px" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from({ length: 10 }).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell><Skeleton width="120px" /></TableCell>
                                    <TableCell><Skeleton width="120px" /></TableCell>
                                    <TableCell><Skeleton width="200px" /></TableCell>
                                    <TableCell><Skeleton width="120px" /></TableCell>
                                    <TableCell><Skeleton width="120px" /></TableCell>
                                    <TableCell>
                                        <Skeleton variant="circle" width={24} height={24} />
                                        <Skeleton variant="circle" width={24} height={24} style={{ marginLeft: '8px' }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            ) : (
                <>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item>
                            <TextField
                                className={classes.searchInput}
                                label={"Pesquisar"}
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ borderRadius: '8px', overflow: 'hidden', border: '2px solid #ed695c70', width: "100%", marginTop: "1rem" }}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Número</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Criado</TableCell>
                                    <TableCell>Atualizado</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {blacklist.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.number}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                                        <TableCell>{new Date(item.updated_at).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEdit(item, setSelectedContact, setOpenModal, setIsEdit)}
                                            >
                                                <EditIcon style={{ color: theme.palette.primary.mainReverseByWhite }} />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDelete(item.id, setBlacklist, blacklist)}
                                            >
                                                <DeleteOutlineIcon style={{ color: theme.palette.primary.mainReverseByWhite }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        className={classes.pagination}
                    />
                </>
            )}
        </Paper>
    );
};

export default BlackListTable;
