import React, { useState } from 'react';
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useStyles from './style';
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore, LocalOffer } from '@material-ui/icons';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import renderActionDetails from './TimelineTicketDetails/renderActionDetails';
import renderActionIcon from './TimelineTicketDetails/renderActionIcon';
import api from '../../services/api';
import toggleOrder from './TimelineTicketDetails/toggleOrder';
import SpyTimelineModal from '../SpyTimelineModal';
import toastError from '../../errors/toastError';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
};

const TimelineTicketDetails = ({ modalOpen, selectedTicket, handleCloseModal, handleTicketClick, setIsAscending, isAscending }) => {
    const classes = useStyles();
    const [isVertical, setIsVertical] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hideLoadMore, setHideLoadMore] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(null);
    const [ticketModalOpen, setTicketModalOpen] = useState(false);
    const [selectedSpy, setSelectedSpy] = useState({ ticketTrakingId: null });

    const handlePlayAudio = async (callId, companyId) => {
        try {
            const { data } = await api.get(`/api/calls/${callId}`, {
                params: { companyId: companyId.toString() },
            });

            if (data.audioAvailable && data.audioUrl) {
                const audioUrl = `${process.env.REACT_APP_BACKEND_URL}${data.audioUrl}`;
                const audio = new Audio(audioUrl);
                setAudioPlaying(audio);
                audio.play();
                audio.onended = () => setAudioPlaying(null);
            }
        } catch (error) {
            toastError("Erro ao carregar o áudio:", error);
        }
    };

    const renderAudioButton = (ticket) => {
        if (ticket.actionType === "Chamada realizada" && ticket.callId) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.audioButton}
                    onClick={() => handlePlayAudio(ticket.callId, ticket.companyId)}
                    disabled={audioPlaying !== null || !ticket.audioAvailable}
                >
                    {audioPlaying ? "Reproduzindo..." : <PlayArrowIcon />}
                </Button>
            );
        }
        return null;
    };

    const toggleDirection = () => {
        setIsVertical(!isVertical);
    };

    const sortedTickets = [...(selectedTicket?.data || [])].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return isAscending ? dateA - dateB : dateB - dateA;
    });

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    const theme = useTheme();

    const handleLoadMore = () => {
        setCurrentPage(prevPage => prevPage + 1);
        const isTotalReached = (currentPage + 1) === selectedTicket.totalPages;
        if (isTotalReached) {
            setHideLoadMore(true);
            return
        }
        handleTicketClick(selectedTicket?.data[0].ticketId, currentPage + 1);
    };

    const handleOpenSpyModal = (spyTicketFound) => {
        setSelectedSpy({ ...spyTicketFound, createdAt: null });
        setTicketModalOpen(true);
    }

    const handleDateSpyModal = (spyTicketFound) => {
        setSelectedSpy(spyTicketFound);
        setTicketModalOpen(true);
    }

    return (
        <>
            {ticketModalOpen && <SpyTimelineModal
                open={ticketModalOpen}
                ticketTrakingId={selectedSpy?.ticketTrakingId}
                onClose={(e) => {
                    e.stopPropagation();
                    setTicketModalOpen(false)
                }}
                ticketId={selectedSpy?.ticketId}
                user={selectedSpy?.user}
                untilDate={selectedSpy?.createdAt}
            />}
            <Fade in={modalOpen} timeout={500}>

                <Box className={classes.modalContainer} onClick={handleClickOutside}>
                    <div className={classes.timelineWrapper} style={{ height: isVertical ? "70vh" : "400px" }}>
                        <div className={classes.timelineTicketHeader}>
                            <h1 className={classes.title}>Timeline Contatos</h1>
                            <div className={classes.ticketButtons}>
                                <Tooltip
                                    title={!isAscending ? "Está em ordem decrescente" : "Está em ordem crescente"}
                                    arrow
                                >
                                    <Button
                                        onClick={() => toggleOrder(setIsAscending, isAscending, handleTicketClick, setHideLoadMore, setCurrentPage, selectedTicket)}
                                        style={{
                                            marginBottom: '20px',
                                            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                                            borderRadius: "8px",
                                            color: theme.palette.primary.mainReverseByWhite,
                                            background: "transparent",
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!isAscending ?
                                            <ExpandMore style={{ transform: isVertical ? "rotate(0deg)" : "rotate(270deg)" }} /> :
                                            <ExpandLess style={{ transform: isVertical ? "rotate(0deg)" : "rotate(270deg)" }} />
                                        }
                                    </Button>
                                </Tooltip>

                                <Button
                                    onClick={toggleDirection}
                                    style={{
                                        marginBottom: '20px',
                                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                                        borderRadius: "8px",
                                        color: theme.palette.primary.mainReverseByWhite,
                                        background: "transparent",
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '10px',
                                    }}
                                >
                                    {isVertical ? 'Vertical' : 'Horizontal'}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.timelineDirection} style={{ flexDirection: isVertical ? 'column' : 'row' }}>
                            {sortedTickets.map((ticket, index) => (
                                <div key={ticket.id} className={classes.timelineContainer}>
                                    <div className={classes.actionType} style={{ minWidth: '300px' }}>
                                        <Typography variant="subtitle1">{ticket.actionType}</Typography>
                                        <div style={{ display: "flex", gap: "1rem", alignItems: "center", cursor: "pointer" }}>

                                            {(index === 0 || ticket.ticketTrakingId !== sortedTickets[index - 1].ticketTrakingId || (sortedTickets[index - 1].actionType === "Chamada realizada" && ticket.actionType !== "Chamada realizada")) && (
                                                <div onClick={() => handleOpenSpyModal(ticket)} className={classes.ticketIcon}>
                                                    <LocalOffer />
                                                </div>
                                            )}

                                            {renderAudioButton(ticket) || <>
                                                {((ticket.actionType !== "Chamada realizada")) && (
                                                    <Tooltip title="Dessa data para trás" arrow>
                                                        <div onClick={() => handleDateSpyModal(ticket)} className={classes.ticketIcon}>
                                                            <ExpandLess />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </>}
                                        </div>
                                        <Box className={classes.actionTypeUserNameWrapper}>
                                            <Avatar
                                                alt={ticket?.user?.name || "Sem atendente"}
                                                src={ticket?.user?.profilePicture ? `${process.env.REACT_APP_BACKEND_URL}${ticket?.user?.profilePicture}` : ""}
                                                className={classes.avatarSmall}
                                            >
                                                {!ticket?.user?.profilePicture && <PersonIcon />}
                                            </Avatar>
                                            <Tooltip title={ticket?.user?.name || "Sem atendente"} arrow>
                                                <Typography variant="span" className={classes.actionTypeUserName}>
                                                    {truncateText(ticket?.user?.name || "Sem atendente", 16)}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                    <div className={classes.actionDetails}>
                                        {renderActionDetails(ticket.actionType, ticket)}
                                    </div>
                                    <div>
                                        {renderActionIcon((ticket.actionType === "Chamada realizada" ? ticket.status : ticket.actionType), classes)}
                                        <div className={classes.actionLine}></div>
                                    </div>
                                </div>
                            ))}

                            {!hideLoadMore && sortedTickets.length >= 10 && (
                                <Button
                                    onClick={handleLoadMore}
                                    color="primary"
                                    style={{
                                        margin: "auto",
                                        display: 'block',
                                        maxHeight: "50px",
                                        border: `2px solid ${theme.palette.primary.main}`,
                                        borderRadius: "8px",
                                    }}
                                >
                                    +
                                </Button>
                            )}
                        </div>
                    </div>
                </Box>
            </Fade>
        </>
    );
};

export default TimelineTicketDetails;
