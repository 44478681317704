import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "none"
    },
    table: {
        minWidth: 650,
        borderRadius: "8px",
        marginTop: "1rem",
        "& th, p, & span,& td": {
            fontFamily: "Poppins",
            fontWeight: '500 !important',
            fontSize: "14px",
            color: theme.palette.primary.mainReverseByWhite

        },

        "& td": {
            color: theme.palette.blackOrWhite
        },
    },
    searchInput: {
        width: "200px",
    },
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

export default useStyles;