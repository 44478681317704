import React from "react";
import { Button, Typography, Select, MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
  },
  button: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
    '&:hover': {
      backgroundColor: theme.palette.primary.mainReverseByWhite,
    },
  },
  modal: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
  },
  select: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
  },
  monthTitle: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
    fontWeight: 600,
    marginBottom: "16px",
  },
}));

const HolidaysCalendar = ({ currentMonth, currentYear, selectedDays, setSelectedDays, setCurrentMonth, setCurrentYear }) => {
  const classes = useStyles();

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const toggleDaySelection = (day) => {
    const formattedDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  
    setSelectedDays((prevSelectedDays) => {
      const isAlreadySelected = prevSelectedDays.includes(formattedDate);
      if (isAlreadySelected) {
        return prevSelectedDays.filter((date) => date !== formattedDate);
      } else {
        return [...prevSelectedDays, formattedDate];
      }
    });
  };

  const isPastDay = (day) => {
    const today = new Date();
    const currentDay = new Date(currentYear, currentMonth, day);
    
    return currentDay < today && !(currentDay.getDate() === today.getDate() && currentDay.getMonth() === today.getMonth() && currentDay.getFullYear() === today.getFullYear());
  };

  const days = Array.from({ length: daysInMonth(currentMonth, currentYear) }, (_, i) => i + 1);

  const getMonthName = (month) => {
    const monthName = new Date(currentYear, month).toLocaleString("pt-BR", { month: "long" });
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
        <Button className={classes.button} onClick={() => setCurrentMonth((prev) => (prev - 1 + 12) % 12)}>Mês Anterior</Button>
        <Select value={currentYear} onChange={(e) => setCurrentYear(Number(e.target.value))} className={classes.select}>
          {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 5 + i).map((year) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </Select>
        <Button className={classes.button} onClick={() => setCurrentMonth((prev) => (prev + 1) % 12)}>Próximo Mês</Button>
      </div>

      <Typography className={classes.monthTitle} variant="h6" align="center">
        {getMonthName(currentMonth)} {currentYear}
      </Typography>

      <Typography className={classes.typography} variant="h6" align="center">
        Selecione os dias
      </Typography>

      <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: "8px", marginTop: "16px" }}>
        {days.map((day) => {
          const formattedDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
          const isHoliday = selectedDays.includes(formattedDate);
          const pastDay = isPastDay(day);
          const isSelected = isHoliday;

          return (
            <Button
              key={day}
              className={classes.button}
              variant={isHoliday ? "contained" : "outlined"}
              color={isHoliday ? "primary" : "default"}
              onClick={() => !pastDay && toggleDaySelection(day)}
              disabled={pastDay}
              style={{
                backgroundColor: isSelected ? theme.palette.primary.main : pastDay ? "#e0e0e0" : "",
                color: isSelected ? "#fff" : pastDay ? "#9e9e9e" : "",
              }}
            >
              {day}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default HolidaysCalendar;
