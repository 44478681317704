import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessTimeIcon from "@material-ui/icons/AccessTime"; // Ícone de tempo
import IconButton from "@material-ui/core/IconButton"; // Botão com ícone
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import useWhatsApps from "../../hooks/useWhatsApps";
import ProfileImageUploader from "../ProfileImageUploader";
import useStyles from "./style";
import ModalForm from "../ModalForm";
import WorkHoursModal from "../WorkHoursModal";

const UserSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
  maxOpenTickets: Yup.number().min(1, "Must be at least 1").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
    wpp: "",
    maxOpenTickets: 10,
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [whatsappId, setWhatsappId] = useState(false);
  const [callRestriction, setCallRestriction] = useState(false);
  const [cannotCall, setCannotCall] = useState(false);
  const { whatsApps } = useWhatsApps();

  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser(prevState => {
          return { ...prevState, ...data };
        });
        const userQueueIds = data?.queues?.map(queue => queue.id) || [];
        setSelectedQueueIds(userQueueIds);
        setCallRestriction(data?.callRestriction);
        setCannotCall(data?.cannotCall);
        setWhatsappId(data.whatsappId ? data.whatsappId : '');
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async values => {
    const userData = { ...values, whatsappId, queueIds: selectedQueueIds, callRestriction: callRestriction, cannotCall};
    
    delete userData.profilePicture;
  
    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Dialog 
        PaperProps={{
          style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
        }} 
        open={open} 
        onClose={handleClose} 
        maxWidth="xs" 
        fullWidth 
        scroll="paper"
      >
        <DialogTitle className={classes.modalTitle} id="form-dialog-title">
          {userId ? `${i18n.t("userModal.title.edit")}` : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              {userId && <ProfileImageUploader userId={userId} />}
              <ModalForm
                  classes={classes}
                  touched={touched}
                  errors={errors}
                  loggedInUser={loggedInUser}
                  selectedQueueIds={selectedQueueIds}
                  setSelectedQueueIds={setSelectedQueueIds}
                  whatsappId={whatsappId}
                  setWhatsappId={setWhatsappId}
                  whatsApps={whatsApps}
                  callRestriction={callRestriction}
                  setCallRestriction={setCallRestriction}
                  cannotCall={cannotCall}
                  setCannotCall={setCannotCall}
              />
              {(loggedInUser?.profile === "admin" || loggedInUser?.profile === "supervisor" || loggedInUser?.profile === "superadmin") && <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "1rem 0"}}>
                <IconButton
                  onClick={() => setIsTimeModalOpen(true)}
                  className={classes.btnStyles}
                  color="primary"
                >
                  <AccessTimeIcon />
                </IconButton>
              </div>}

              <Dialog 
                open={isTimeModalOpen} 
                onClose={() => setIsTimeModalOpen(false)}
              >
                <WorkHoursModal user={user} setIsTimeModalOpen={setIsTimeModalOpen} isTimeModalOpen={isTimeModalOpen}/>
              </Dialog>

              <DialogActions>
                <Button
                  className={classes.btnStyles}
                  onClick={handleClose}
                  disabled={isSubmitting}
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.btnStyles}
                >
                  {userId ? `${i18n.t("userModal.buttons.okEdit")}` : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
