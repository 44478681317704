import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import api from "../../services/api";
import { has } from "lodash";
import GroupIcon from "@material-ui/icons/Group";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useDate } from "../../hooks/useDate";
import { socketConnection } from "../../services/socket";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CampaignMessagesTable from "../../components/CampainMessagesTable";
import ConnectionsDropdown from "../../components/ConnectionsDropdown";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: "1rem",
    ...theme.scrollbarStyles,
    border: "none",
    background: "transparent"
  },
  textRight: {
    textAlign: "right",
  },
  tabPanelsContainer: {
    display: "flex",
    flexDirection: "column",
  },

  titleStyles: {
    fontWeight: "bold",
    fontSize: "12px",
    marginBottom: "1rem",
    color: theme.palette.primary.main
  },

  boxItemStyles: {
    background: theme.palette.primary.secondGradient,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Poppins",
    height: "100%"
  }
}));

const CampaignReport = () => {
  const classes = useStyles();
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});
  const [confirmationRequested, setConfirmationRequested] = useState(0);
  const [confirmed, setConfirmed] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);
  const { datetimeToClient } = useDate();
  const history = useHistory();
  const theme = useTheme();

  const findCampaign = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/campaigns/${campaignId}`);
      setCampaign(data);
    } catch (error) {
      console.error("Error fetching campaign:", error);
    }
    setLoading(false);
  }, [campaignId]);

  const findCampaignRef = useRef(findCampaign);

  useEffect(() => {
    findCampaignRef.current = findCampaign;
  }, [findCampaign]);

  useEffect(() => {
    if (mounted.current) {
      findCampaignRef.current();
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && has(campaign, "shipping")) {

      setConfirmationRequested(confirmationRequested?.length || 0);
      setConfirmed(confirmed.length);
    }
  }, [campaign]);

  useEffect(() => {
    setPercent(campaign.totalMessages > 0 ? (campaign.sentMessages / campaign.totalMessages) * 100 : 100);
  }, [campaign]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.record.id === +campaignId) {
        setCampaign(data.record);
        if (data.record.status === "FINALIZADA") {
          setTimeout(() => {
            findCampaign();
          }, 5000);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [campaignId, findCampaign]);



  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return "Inativa";
      case "PROGRAMADA":
        return "Programada";
      case "EM_ANDAMENTO":
        return "Em Andamento";
      case "CANCELADA":
        return "Cancelada";
      case "FINALIZADA":
        return "Finalizada";
      default:
        return val;
    }
  };

  return (
    <MainContainer>
      <MainHeader>
        <Grid style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }} container>
          <Grid xs={12} item>
            <Title>Relatório da {campaign.name || "Campanha"}</Title>
          </Grid>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              variant="contained"
              style={{
                padding: "0",
                border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                color: theme.palette.primary.mainReverseByWhite,
                fontWeight: "bold",
                background: "transparent",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: "25px",
                height: "25px",
                minWidth: "25px",
              }}
              onClick={() => history.push("/campaigns")}
            >
              <ArrowBackIcon style={{ margin: "0", padding: "0", fontSize: "1rem" }} /> {/* Tamanho do ícone ajustado */}
            </Button>
          </Box>
        </Grid>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Grid container spacing={3} style={{ display: "flex", flexDirection: "column" }}>
          <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: "1rem" }}>
            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <GroupIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                  <div>
                    <Typography className={classes.titleStyles}>Contatos Válidos</Typography>
                    <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : campaign?.totalMessages}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                  <div>
                    <Typography className={classes.titleStyles}>Entregues</Typography>
                    <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : campaign?.sentMessages}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            {campaign.whatsappId && (
              <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WhatsAppIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                    <div>
                      <Typography className={classes.titleStyles}>Conexão</Typography>
                      <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : campaign.whatsapp.name}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {campaign.contactListId && (
              <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ListAltIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                    <div>
                      <Typography className={classes.titleStyles}>Lista de Contatos</Typography>
                      <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : campaign.contactList.name}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ScheduleIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                  <div>
                    <Typography className={classes.titleStyles}>Agendamento</Typography>
                    <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : datetimeToClient(campaign.scheduledAt)}</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            {/* Lista de contatos */}
            {campaign.contactList && (
              <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                <div
                  className={classes.boxItemStyles}
                  style={{
                    padding: "16px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListAltIcon fontSize="inherit" style={{ marginRight: "8px", fontSize: "2rem", color: theme.palette.primary.main }} />
                    <div>
                      <Typography className={classes.titleStyles}>Lista de Contatos</Typography>
                      <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : campaign.contactList.name}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )}


            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EventAvailableIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem', color: theme.palette.primary.main }} />
                  <div>
                    <Typography className={classes.titleStyles}>Conclusão</Typography>
                    <Typography className={classes.titleStyles} variant="span">{loading ? "Carregando..." : datetimeToClient(campaign.completedAt)}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <ConnectionsDropdown
              campaign={campaign}
              classes={classes}
              theme={theme}
            />
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" component="h2" style={{ marginTop: "11rem", color: "lightgreen", fontFamily: "Poppins", fontWeight: "bold", textAlign: "center" }}>
              Status: {formatStatus(campaign.status)} {campaign.sentMessages} de {campaign.totalMessages}
            </Typography>
            <Grid xs={12} item>
              <LinearProgress
                variant="determinate"
                style={{ height: 15, borderRadius: 100, margin: "20px 0" }}
                value={percent}
              />
            </Grid>
          </Box>
        </Grid>
        <CampaignMessagesTable campaignId={campaignId}/>
      </Paper>
    </MainContainer>
  );
};

export default CampaignReport;
