import React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import handleCloseModal from "../../pages/Blacklist/BlacklistComponent/handles/handleCloseModal";
import handleSave from "../../pages/Blacklist/BlacklistComponent/handles/handleSave";
import handleCreate from "../../pages/Blacklist/BlacklistComponent/handles/handleCreate";

const BlackListEditContactModal = ({ openModal, setOpenModal, setSelectedContact, selectedContact, setBlacklist, blacklist, isEdit }) => {
    const contact = selectedContact || { name: "", number: "", email: "" };

    const handleNumberChange = (e) => {
        const input = e.target.value;
        const sanitizedInput = input.replace(/\D/g, "");
        setSelectedContact({ ...contact, number: sanitizedInput });
    };

    return (
        <Dialog open={openModal} onClose={() => handleCloseModal(setOpenModal, setSelectedContact)}>
            <DialogTitle>{isEdit ? "Editar Contato" : "Adicionar Usuário"}</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        label="Nome"
                        value={contact.name}
                        onChange={(e) => setSelectedContact({ ...contact, name: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Número"
                        value={contact.number}
                        onChange={handleNumberChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Restringe o teclado para números em dispositivos móveis
                    />
                    <TextField
                        label="Email"
                        value={contact.email}
                        onChange={(e) => setSelectedContact({ ...contact, email: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseModal(setOpenModal, setSelectedContact)} color="primary">
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        if (isEdit) {
                            handleSave(contact, setBlacklist, blacklist, setOpenModal, setSelectedContact);
                        } else {
                            handleCreate(contact, setBlacklist, blacklist, setOpenModal, setSelectedContact);
                        }
                    }}
                    color="primary"
                >
                    {isEdit ? "Salvar" : "Adicionar"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BlackListEditContactModal;
