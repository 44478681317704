import React from "react";
import { GroupOutlined } from "@material-ui/icons";

const GroupsInfo = ({ groupGraphicsActive, theme, groupsFound, attendants}) => {
  const onlineCount = !isNaN(Number(attendants)) && attendants !== undefined && attendants !== null
    ? Number(attendants)
    : 0;

  return (
    <>
        {groupGraphicsActive === "grupos" && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "3rem" }}>
            <div style={{
              borderRadius: "8px",
              background: theme.palette.primary.main,
              width: "150px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "0.5rem"
            }}>
              <h6 style={{ fontFamily: "Poppins", fontSize: "16px", color: "white" }}>Grupos</h6>
              <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <GroupOutlined style={{ color: "white" }} />
                <span style={{ fontFamily: "Poppins", color: "white" }}>{groupsFound?.groupTicketSummary?.totalTickets}</span>
              </div>
            </div>
            <div style={{
              borderRadius: "8px",
              background: theme.palette.primary.main,
              width: "150px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "0.5rem"
            }}>
              <h6 style={{ fontFamily: "Poppins", fontSize: "16px", color: "white", textAlign: "center" }}>At. online</h6>
              <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <div style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "green"
                }} />
                <span style={{ fontFamily: "Poppins", color: "white" }}>{onlineCount}</span>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default GroupsInfo;
