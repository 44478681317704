import React, { useState, useEffect, useRef } from "react";
import MainContainer from "../../components/MainContainer";
import useStyles from "./styles";
import api from "../../services/api";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import ContactTickets from "../../components/ContactTickets";
import TimelineHeader from "./TimelineComponents/TimelineHeader";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import TimelineTicketDetails from "../../components/TimelineTicketDetails";
import toastError from "../../errors/toastError";

const Timeline = () => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState("");
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [ticketsData, setTicketsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isAscending, setIsAscending] = useState(true);

    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchContactTickets = async (contactId, page = 1, pageSize = 10, order = "asc") => {
        setIsLoading(true);
        try {
            const response = await api.get(
                `/timeline/tickets/${contactId}?page=${page}&pageSize=${pageSize}&order=${order}`
            );

            setTicketsData((prev) => [...prev, ...response.data]);
        } catch (error) {
            setTicketsData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFilteredContacts = async (query) => {
        setIsLoading(true);
        try {
            const response = await api.get(`/contact-timeline?contactName=${query}`);
            setContacts(response.data);
        } catch (error) {
            console.error("Erro ao buscar contatos filtrados:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        setDropdownVisible(query.trim() !== "");

        if (query.trim() !== "") {
            fetchFilteredContacts(query);
        }
    };

    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        setSearchQuery(contact.contactName);
        setDropdownVisible(false);
        setTicketsData([]);
        fetchContactTickets(contact.contactId);
    };

    const handleTicketClick = async (ticketId, page = 1, pageSize = 10, order = isAscending ? "asc" : "desc") => {
        
        try {
            const response = await api.get(`/timeline/${ticketId}?page=${page}&pageSize=${pageSize}&order=${order}`);
            if (response.data) {
                if (page === 1) {
                    setSelectedTicket(response.data);
                } else {
                    setSelectedTicket((prevState) => ({
                        ...prevState,
                        data: [...(prevState?.data || []), ...response.data.data],
                    }));
                }
            }
    
            setModalOpen(true);
        } catch (error) {
            toastError("Erro ao buscar detalhes do ticket:", error);
        }
    };
    

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedTicket(null);
    };

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;

        if (scrollTop + clientHeight >= scrollHeight - 50 && !isLoading && selectedContact) {
            fetchContactTickets(selectedContact.contactId, ticketsData.length / 10 + 1);
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <TimelineHeader
                classes={classes}
                searchRef={searchRef}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                setDropdownVisible={setDropdownVisible}
                dropdownVisible={dropdownVisible}
                contacts={contacts}
                selectedContact={selectedContact}
                handleSelectContact={handleSelectContact}
            />

            <div className={classes.selectedContactContainer}>
                {selectedContact ? (
                    <div className={classes.selectedContact}>
                        <div className={classes.avatarContainer}>
                            {selectedContact.profilePicUrl ? (
                                <Avatar
                                    alt={selectedContact.contactName}
                                    src={selectedContact.profilePicUrl}
                                    className={classes.avatar}
                                />
                            ) : (
                                <Avatar className={classes.defaultAvatar}>
                                    <PersonIcon />
                                </Avatar>
                            )}
                        </div>
                        <div className={classes.contactNameAndActivities}>
                            <h3 className={classes.contactName}>{selectedContact.contactName}</h3>
                            <p className={classes.activitiesCount}>
                                {selectedContact.ticketTrakingCount || 0} ações
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className={`${classes.noContactSelected} ${classes.selectedContact}`}>
                        <Avatar className={classes.defaultAvatar}>
                            <PersonIcon />
                        </Avatar>
                        <p>Selecione um contato</p>
                    </div>
                )}
            </div>

            <div className={classes.ticketsData} onScroll={handleScroll}>
                {isLoading ? (
                    <div className={classes.loadingMessage}>Carregando tickets...</div>
                ) : (
                    <ContactTickets ticketsArray={ticketsData} onTicketClick={handleTicketClick} />
                )}
            </div>

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <TimelineTicketDetails
                    setIsAscending={setIsAscending}
                    isAscending={isAscending}
                    modalOpen={modalOpen}
                    selectedTicket={selectedTicket}
                    handleCloseModal={handleCloseModal}
                    handleTicketClick={handleTicketClick}
                    ticketId={selectedTicket?.id}
                />
            </Modal>
        </MainContainer>
    );
};

export default Timeline;
