import { useTheme } from "@material-ui/core";
import React from "react";

const DashboardItem = ({ title, icon: Icon, value, classes }) => {
  const theme = useTheme();
  return (
    <div className={`cmp-dashboard__raw-data-item ${classes.dashboardHeaderItem}`}>
      <div>
        <h2 style={{color: theme.palette.blackOrWhite, fontSize: "16px", fontWeight: "400"}}>{title}</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <Icon style={{color: theme.palette.primary.main}}/>
        <span style={{color: theme.palette.primary.main}}>{value}</span>
      </div>
    </div>
  );
};

export default DashboardItem;
