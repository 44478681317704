import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Paper, Typography,
    makeStyles,
    Box,
    Grid,
} from '@material-ui/core';
import api from '../../services/api';
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import baileyIcon from "../../assets/baileys-icon.png";
import getSocialBackgroundColor from '../../utils/socialMediaBackground';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: theme.spacing(2),
        flexBasis: "calc(100% - 1rem)",
        fontFamily: "'Poppins', sans-serif",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        margin: "1rem 0"
    },
    table: {
        fontFamily: "'Poppins', sans-serif",
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: theme.palette.grey[200],
    },
    tableCell: {
        padding: theme.spacing(2),
        fontFamily: "'Poppins', sans-serif",
        fontSize: '0.9rem',
        color: theme.palette.primary.main,
    },
    title: {
        color: theme.palette.primary.main,
        fontFamily: "'Poppins', sans-serif",
        marginBottom: theme.spacing(2),
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    skeletonTableCell: {
        padding: theme.spacing(2),
        fontSize: '0.9rem',
    },
}));

const CampaignMessagesTable = ({ campaignId }) => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/campaigns/${campaignId}/messages`, {
                    params: { page, limit: 10 },
                });
                setMessages(response.data.messages);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [campaignId, page]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Grid item xs={12}> {/* Adicionando o Grid com xs={12} */}
            <Paper className={classes.tableContainer}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                    Mensagens da campanha
                </Typography>
                {loading ? (
                    <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from({ length: 10 }).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                    <TableCell className={classes.skeletonTableCell}><Skeleton /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.tableCell}>ID</TableCell>
                                <TableCell className={classes.tableCell}>Mensagem</TableCell>
                                <TableCell className={classes.tableCell}>Número</TableCell>
                                <TableCell className={classes.tableCell}>Data de Entrega</TableCell>
                                <TableCell className={classes.tableCell}>Data de Criação</TableCell>
                                <TableCell className={classes.tableCell}>Nome no WhatsApp</TableCell>
                                <TableCell className={classes.tableCell}>Tipo de WhatsApp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {messages.map((msg) => (
                                <TableRow key={msg.id}>
                                    <TableCell className={classes.tableCell}>{msg?.id || ""}</TableCell>
                                    <TableCell className={classes.tableCell}>{msg?.message || ""}</TableCell>
                                    <TableCell className={classes.tableCell}>{msg?.number || ""}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {msg?.deliveredAt ? new Date(msg.deliveredAt).toLocaleString() : 'Não entregue'}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>{new Date(msg.createdAt).toLocaleString()}</TableCell>
                                    <TableCell className={classes.tableCell}>{msg?.whatsapp?.name || ""}</TableCell>
                                    <TableCell className={classes.tableCell}>{msg?.whatsapp?.type ? <WhatsAppIcon style={{ background: getSocialBackgroundColor('official'), borderRadius: '8px', color: "#FFF" }} /> : <img style={{ width: "25px", backgroundColor: getSocialBackgroundColor('baileys'), borderRadius: "8px" }} src={baileyIcon} alt="DialogFlow" />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <Box className={classes.paginationContainer}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </Paper>
        </Grid>
    );
};

export default CampaignMessagesTable;
