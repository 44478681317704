import { toast } from "react-toastify";
import { isArray } from "lodash";

import { isEmpty } from "lodash";
import moment from "moment";
import api from "../../../../services/api";

async function fetchData(setLoading, dateFrom, dateTo, selectedGroupId, setGroupsFound, find, user, setCounters, setAllTicketsCount, setLastTickets, setAttendants, period, groupList, page, searchTerm, setMessages, setTotalMessages, selectedUser, setPrivateHeatmapFound) {
    setLoading(true);
    const getGroupMessages = async () => {
        const { data } = await api.get("/group-messages-report", { params: { startDate: dateFrom, endDate: dateTo, groupId: selectedGroupId } });
        setGroupsFound(data);
    }

    const getPrivateMessages = async () => {
        const { data } = await api.get("/privateMessages", {
            params: {
                startDate: dateFrom,
                endDate: dateTo
            },
        });
        setPrivateHeatmapFound(data);
    };
    let params = {};
    const ticketId = groupList.find((groupFound) => Number(groupFound.id) === Number(selectedGroupId));

    const fetchMessages = async () => {
        try {
            const response = await api.get("/groupsMessages", {
                params: {
                    page: page + 1,
                    searchParam: searchTerm,
                    ticketId: ticketId?.ticketId || null,
                    startDate: dateFrom,
                    endDate: dateTo,
                    userId: selectedUser || null
                }
            });
            setMessages(response.data.messages);
            setTotalMessages(response.data.Total);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    fetchMessages();
    if (period > 0) {
        params = {
            days: period,
        };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
        params = {
            ...params,
            date_from: moment(dateFrom).format("YYYY-MM-DD"),
        };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
        params = {
            ...params,
            date_to: moment(dateTo).format("YYYY-MM-DD"),
        };
    }

    if (Object.keys(params).length === 0) {
        toast.error("Parametrize o filtro");
        setLoading(false);
        return;
    }

    const data = await find(user.super ? {} : params);
    await getGroupMessages();
    await getPrivateMessages();
    if (!data) {
        return
    }
    setCounters(user.super ? data : data.counters);
    setAllTicketsCount(data)
    setLastTickets(data.tickets);

    if (isArray(data.attendants) || data.onlineUsers) {
        setAttendants(user.super ? data.onlineUsers : data.attendants);
    } else {
        setAttendants([]);
    }

    setLoading(false);
}

export default fetchData;
