import React, { useState } from "react";

import { IconButton, TextField } from "@material-ui/core";
import { Group, Person } from "@material-ui/icons";
import ExportButton from "../DashboardDownloadButton";

const TabsHeader = ({
  setGroupGraphicsActive,
  groupGraphicsActive,
  theme,
  selectedGroupId,
  setSelectedGroupId,
  groupList,
  groupsFound,
  searchTerm,
  startDate,
  endDate,
  selectedUser,
  user,
  users
}) => {
  const [filter, setFilter] = useState("");

  const filteredGroups = groupList?.filter((group) =>
    group.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        padding: "0 2rem",
        paddingTop: "32px",
        paddingBottom: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "32px",
          paddingBottom: "0",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <div onClick={() => setGroupGraphicsActive("privado")} style={{ display: "flex", gap: "0.5rem", alignItems: "center", cursor: "pointer" }}>
            <IconButton
              style={{
                background:
                  groupGraphicsActive === "privado"
                    ? theme.palette.primary.main
                    : "#737373",
                padding: "8px",
                fontSize: "16px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Person style={{ color: "white" }} />
            </IconButton>
            <label style={{
              fontFamily: "Poppins", color: groupGraphicsActive === "privado"
                ? theme.palette.primary.main
                : "#737373"
            }}>Dados individuais</label>
          </div>
          <div onClick={() => setGroupGraphicsActive("grupos")} style={{ display: "flex", gap: "0.5rem", alignItems: "center", cursor: "pointer" }}>
            <IconButton
              style={{
                background:
                  groupGraphicsActive === "grupos"
                    ? theme.palette.primary.main
                    : "#737373",
                padding: "8px",
                fontSize: "16px",
              }}
            >
              <Group style={{ color: "white" }} />
            </IconButton>
            <label style={{
              fontFamily: "Poppins", color: groupGraphicsActive !== "privado"
                ? theme.palette.primary.main
                : "#737373"
            }}>Dados de grupos</label>
          </div>
        </div>
        {!user.super && <div style={{ display: "flex", alignItems: "center" }}>
          {groupGraphicsActive === "grupos" && (
            <ExportButton
              startDate={startDate}
              endDate={endDate}
              searchTerm={searchTerm}
              groupsFound={groupsFound || []}
              groupList={groupList}
              selectedGroupId={selectedGroupId}
              selectedUser={selectedUser}
              users={users}
            />
          )}
          {groupGraphicsActive === "grupos" && (
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <TextField
                id="search-input"
                size="small"
                placeholder="Pesquisar grupos"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={{
                  minWidth: "200px",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  color: "black"
                }}
              />
              <div
                style={{
                  maxHeight: "500px",
                  minWidth: "250px",
                  overflowY: "auto",
                  border: `1px solid ${theme.palette.gray || "#ccc"}`,
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <select
                  id="group-select"
                  value={selectedGroupId}
                  onChange={(e) => setSelectedGroupId(e.target.value)}
                  style={{
                    padding: "8px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "none",
                    width: "100%",
                    outline: "none",
                  }}
                >
                  <option value="">Todos</option>
                  {filteredGroups?.map((group, index) => (
                    <option key={index} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>}
      </div>
    </div>
  );
};

export default TabsHeader;
