import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Grid, Tooltip } from "@material-ui/core";
import MainHeader from "../../../../components/MainHeader";
import Title from "../../../../components/Title";
import { FaUserPlus, FaUpload } from "react-icons/fa";
import { GetApp } from "@material-ui/icons";
import handleFileUpload from "../handles/handleFileUpload";
import handleDownloadTemplate from "../handles/handleDownloadTemplate";

const BlackListHeader = ({ theme, setIsLoading, setBlacklist, setTotalPages, currentPage, setEdit, setModalOpen }) => {
    return (
        <MainHeader>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Title>Blacklist</Title>
                </Grid>
                <Grid item>
                    <Tooltip title="Adicionar Usuário" arrow>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setEdit(false);
                                setModalOpen(true);
                            }}
                            style={{
                                width: "40px",
                                height: "40px",
                                padding: "0",
                                borderRadius: "8px",
                                border: `2px solid ${theme.palette.primary.mainReverseByWhite}`
                            }}
                        >
                            <FaUserPlus style={{ fontSize: "24px", color: theme.palette.primary.mainReverseByWhite }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Upload Planilha" arrow>
                        <IconButton
                            color="secondary"
                            component="label"
                            style={{
                                marginLeft: "10px",
                                width: "40px",
                                height: "40px",
                                padding: "0",
                                borderRadius: "8px",
                                border: `2px solid ${theme.palette.primary.mainReverseByWhite}`
                            }}
                        >
                            <input
                                type="file"
                                hidden
                                accept=".xlsx, .xls"
                                onChange={(e) => handleFileUpload(e, setIsLoading, setBlacklist, setTotalPages, currentPage)}
                            />
                            <FaUpload style={{ fontSize: "24px", color: theme.palette.primary.mainReverseByWhite }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Baixar Modelo" arrow>
                        <IconButton
                            color="primary"
                            onClick={handleDownloadTemplate}
                            style={{
                                marginLeft: "10px",
                                width: "40px",
                                height: "40px",
                                padding: "0",
                                borderRadius: "8px",
                                border: `2px solid ${theme.palette.primary.mainReverseByWhite}`
                            }}
                        >
                            <GetApp style={{ fontSize: "24px", color: theme.palette.primary.mainReverseByWhite }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </MainHeader>
    );
};

export default BlackListHeader;
