import { useState, useEffect, useRef } from 'react';
import themeDefault from '../../static/themeDefault';
import { useLocation } from "react-router-dom";

const usePageTheme = () => {
  const [pageTheme, setPageTheme] = useState(null);
  const location = useLocation();
  const isAuthRoute = ["/login", "/signup", "/recovery-password"].includes(location.pathname);
  const isAuthRouteRef = useRef(isAuthRoute);

  useEffect(() => {
    isAuthRouteRef.current = isAuthRoute;
  }, [isAuthRoute]);

  useEffect(() => {
    const fetchTheme = async () => {
      const savedTheme = JSON.parse(localStorage.getItem("pageTheme")) || themeDefault;
      if (savedTheme) {
        if (isAuthRouteRef.current) {
          setPageTheme(themeDefault);
          localStorage.setItem("pageTheme", JSON.stringify(themeDefault));
        } else {
          setPageTheme(savedTheme);
          localStorage.setItem("pageTheme", JSON.stringify(savedTheme));
        }
      } else {
        localStorage.setItem("pageTheme", JSON.stringify(themeDefault));
        setPageTheme(themeDefault);
      }
    }

    fetchTheme();
  }, []); 

  return { pageTheme: pageTheme, setPageTheme };
};

export default usePageTheme;
