import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    BarChart,
    CartesianGrid,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
    const theme = useTheme();
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', color: theme.palette.primary.main }}>
                <p>{`Usuário: ${payload[0].payload.fullName}`}</p>
                <p>{`Mensagens: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const GroupRankingChart = ({ attendants }) => {
    const theme = useTheme();
    const [chartData, setChartData] = useState([]);
    
    useEffect(() => {
        const sortedData = attendants
            .map(user => {
                const fullName = user.userName;
                const initial = user.userName.charAt(0);
                return {
                    fullName: fullName,
                    sellerName: initial,
                    sellerTicketCount: user.messageCount,
                };
            })
            .sort((a, b) => b.sellerTicketCount - a.sellerTicketCount)
            .slice(0, 5);

        setChartData(sortedData);
    }, [attendants]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '250px' }}>
                <h3 style={{color: theme.palette.blackOrWhite, fontSize: "16px", fontWeight: "400"}}>{`Contagem de mensagens`}</h3>
                <ResponsiveContainer>
                    <BarChart data={chartData} layout="vertical" barSize={20}>
                        <CartesianGrid stroke="#e0e0e0" vertical={false} />
                        <XAxis type="number" stroke={theme.palette.text.secondary} />
                        <YAxis dataKey="sellerName" type="category" stroke={theme.palette.text.secondary} />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="sellerTicketCount" fill="rgba(0, 123, 255, 0.7)" animationDuration={500} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    );
};

export default GroupRankingChart;
