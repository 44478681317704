import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    BarChart,
    CartesianGrid,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
    const theme = useTheme();
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', color: theme.palette.primary.mainReverseByWhite }}>
                <p>{`Atendente: ${payload[0].payload.fullName}`}</p> {/* Exibe o nome completo na tooltip */}
                <p>{`Tickets: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const SellerRankingChart = ({ attendants }) => {
    const theme = useTheme();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const sortedData = attendants
            .map(ticket => {
                const fullName = ticket.name; // Nome completo
                const initial = ticket.name.charAt(0); // Pegando a inicial
                return {
                    fullName: fullName, // Nome completo para a tooltip
                    sellerName: initial, // Apenas a inicial para o gráfico
                    sellerTicketCount: ticket.tickets,
                };
            })
            .sort((a, b) => b.sellerTicketCount - a.sellerTicketCount) // Ordenando de forma decrescente
            .slice(0, 5); // Pegando os 5 primeiros

        setChartData(sortedData);
    }, [attendants]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '220px' }}>
                <h3 style={{color: theme.palette.blackOrWhite, fontWeight: "400"}}>{`Ranking Atendentes`}</h3>
                <ResponsiveContainer>
                    <BarChart data={chartData} layout="vertical" barSize={40}>
                        <CartesianGrid stroke="#e0e0e0" vertical={false} />
                        <XAxis type="number" stroke={theme.palette.text.secondary} />
                        <YAxis dataKey="sellerName" type="category" stroke={theme.palette.text.secondary} />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="sellerTicketCount" fill="rgba(0, 123, 255, 0.7)" animationDuration={500} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    );
};

export default SellerRankingChart;
