import Routes from "./routes";
import faviconFlow from './assets/favicons/faviconConnect.ico';
import favicon from './assets/favicons/favicon.ico';
import faviconPurple from './assets/favicons/faviconp.ico';
import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ColorModeContext from "./layout/themeContext";
import { AuthProvider } from "./context/Auth/AuthContext"; // Importar o AuthProvider corretamente
import usePageTheme from "./hooks/usePageTheme"; // Importar o hook
import moment from "moment";
import DashboardContext from "./context/DashboardContext";
import { WavoipProvider } from "./context/WavoipContext/WhavoipContext";
import faviconTheme from "./assets/envAssets/favicon";

const defaultLogoLight = "assets/vector/logo.svg";
const defaultLogoDark = "assets/vector/logo-dark.svg";

const queryClient = new QueryClient();
const App = () => {
    const [locale, setLocale] = useState();
    const preferredTheme = (localStorage.getItem("preferredTheme") && localStorage.getItem("preferredTheme").length > 0 && localStorage.getItem("preferredTheme") !== "null") ? localStorage.getItem("preferredTheme") : "light";
    const [counters, setCounters] = useState({});
    const [attendants, setAttendants] = useState([]);
    const [lastTickets, setLastTickets] = useState([]);
    const [allTicketsCount, setAllTicketsCount] = useState(null)
    const [filterType, setFilterType] = useState(1);
    const [reportCount, setReportCounts] = useState(null);
    const [period, setPeriod] = useState(0);
    const [companyDueDate, setCompanyDueDate] = useState();
    const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
    const [dateFrom, setDateFrom] = useState(
      moment(dateTo).subtract(1, "days").format("YYYY-MM-DD")
    );
    const [serviceQuality, setServiceQuality] = useState(null);
    const [primaryColorLight, setPrimaryColorLight] = useState("#F3F3F3");
    const [primaryColorDark, setPrimaryColorDark] = useState("#333333");
    const [appLogoLight, setAppLogoLight] = useState("");
    const [appLogoDark, setAppLogoDark] = useState("");
    const [appLogoFavicon, setAppLogoFavicon] = useState("");
    const [appName, setAppName] = useState("");
    const [chatlistLight, setChatlistLight] = useState("#FFFFFF");
    const [chatlistDark, setChatlistDark] = useState("#999999");
    const [boxRightLight, setBoxRightLight] = useState("#39ACE7");
    const [boxRightDark, setBoxRightDark] = useState("#39ACE7");
    const [boxLeftLight, setBoxLeftLight] = useState("#39ACE7");
    const [boxLeftDark, setBoxLeftDark] = useState("#39ACE7");
    const [mode, setMode] = useState(!preferredTheme ? "light" : preferredTheme);
    const { pageTheme, setPageTheme } = usePageTheme();

    const updateFavicon = (faviconPath) => {
        const link = document.querySelector("#favicon-link");
        if (!link) {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = faviconPath;
            document.head.appendChild(newLink);
        } else {
            link.href = faviconPath;
        }
    };

    useEffect(() => {
        const faviconChosen = faviconTheme[process.env.REACT_APP_THEME_LOGO];

        const themeFavicon = faviconChosen;
        updateFavicon(themeFavicon);
    }, []);

    const setPageThemeRef = useRef(setPageTheme);

    useEffect(() => {
      setPageThemeRef.current = setPageTheme;
    }, [setPageTheme]);
    
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
    
            setPrimaryColorLight: (color) => {
                if (!color || !color.startsWith("#")) {
                    color = "#0000FF";
                }
                setPrimaryColorLight(color);
            },
            setPrimaryColorDark: (color) => {
                setPrimaryColorDark(color);
            },
            setAppLogoLight: (file) => {
                setAppLogoLight(file);
            },
            setAppLogoDark: (file) => {
                setAppLogoDark(file);
            },
            setAppLogoFavicon: (file) => {
                setAppLogoFavicon(file);
            },
            setAppName: (name) => {
                setAppName(name);
            },
            setChatlistLight: (color) => {
                setChatlistLight(color);
            },
            setChatlistDark: (color) => {
                setChatlistDark(color);
            },
            setBoxLeftLight: (color) => {
                setBoxLeftLight(color);
            },
            setBoxLeftDark: (color) => {
                setBoxLeftDark(color);
            },
            setBoxRightLight: (color) => {
                setBoxRightLight(color);
            },
            setBoxRightDark: (color) => {
                setBoxRightDark(color);
            },
            setPageTheme: (color) => {
                setPageThemeRef.current(color);
            },
        }),
        []
    );

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                    borderRadius: "8px",
                    transition: ".3s", // Transição para o scrollbar
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'none',
                    backgroundColor: mode === "light" ? `rgba(${primaryColorLight}, 0)` : `rgba(${primaryColorDark}, 0)`, // Ocultar inicialmente
                    borderRadius: "8px",
                    transition: "background-color 0.3s ease, box-shadow 0.3s ease", // Transição para o thumb
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: mode === "light" ? `rgba(${primaryColorLight}, 1)` : `rgba(${primaryColorDark}, 1)`, // Mostrar scrollbar ao passar o mouse
                },
            },

            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "##FC9C33" : "#fff !important",
                    borderRadius: "8px",
                },
            },



            palette: {
                type: mode,
                whiteOrOrangeGradient: mode === "light" ? "#FFFFFF" : "linear-gradient(90deg, #FFA500, #FF7F00)",
                orangeOrGray: mode === "light" ? "linear-gradient(90deg, #FFA500, #FF7F00)" : "gray",
                whiteOrGray: mode === "light" ? "#FFFFFF" : "gray",
                blackOrWhite: mode === "light" ? "black" : "#FFFFFF",
                primary: {
                    main: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_MAIN_COLOR : pageTheme.REACT_APP_LIGHT_MAIN_COLOR)
                        : (!pageTheme ? process.env.REACT_APP_DARK_MAIN_COLOR : pageTheme.REACT_APP_DARK_MAIN_COLOR),
                    second: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_SECOND_COLOR : pageTheme.REACT_APP_LIGHT_SECOND_COLOR)
                        : (!pageTheme ? process.env.REACT_APP_DARK_SECOND_COLOR : pageTheme.REACT_APP_DARK_SECOND_COLOR),
                    third: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_THIRD_COLOR : pageTheme.REACT_APP_LIGHT_THIRD_COLOR)
                        : (!pageTheme ? process.env.REACT_APP_DARK_THIRD_COLOR : pageTheme.REACT_APP_DARK_THIRD_COLOR),
                    text: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_TEXT_COLOR : pageTheme.REACT_APP_LIGHT_TEXT_COLOR)
                        : (!pageTheme ? process.env.REACT_APP_DARK_TEXT_COLOR : pageTheme.REACT_APP_DARK_TEXT_COLOR),
                    links: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_LINKS_COLOR : pageTheme.REACT_APP_LIGHT_LINKS_COLOR)
                        : (!pageTheme ? process.env.REACT_APP_DARK_LINKS_COLOR : pageTheme.REACT_APP_DARK_LINKS_COLOR),
                    mainGradient: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_MAIN_GRADIENT : pageTheme.REACT_APP_LIGHT_MAIN_GRADIENT)
                        : (!pageTheme ? process.env.REACT_APP_DARK_MAIN_GRADIENT : pageTheme.REACT_APP_DARK_MAIN_GRADIENT),
                    secondGradient: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_SECOND_GRADIENT : pageTheme.REACT_APP_LIGHT_SECOND_GRADIENT)
                        : (!pageTheme ? process.env.REACT_APP_DARK_SECOND_GRADIENT : pageTheme.REACT_APP_DARK_SECOND_GRADIENT),
                    reverseWhiteByMain: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN : pageTheme.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN)
                        : (!pageTheme ? process.env.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN : pageTheme.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN),
                    mainReverseByWhite: mode === "light"
                        ? (!pageTheme ? process.env.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE : pageTheme.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE)
                        : (!pageTheme ? process.env.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE : pageTheme.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE),
                    whiteGradient: mode === "light"
                        ? "linear-gradient(to right, white, white)"
                        : "linear-gradient(to right, #262525, #4a4848)"
                },

                second: mode === "light" ? "#FF7F00" : "#FFFFFF",
                tertiary: mode === "light"
                    ? (!pageTheme ? process.env.REACT_APP_LIGHT_THIRD_COLOR : pageTheme.REACT_APP_LIGHT_THIRD_COLOR)
                    : (!pageTheme ? process.env.REACT_APP_DARK_THIRD_COLOR : pageTheme.REACT_APP_DARK_THIRD_COLOR),

                textPrimary: mode === "light" ? "#FC9C33" : "#FFFFFF",
                borderPrimary: mode === "light" ? "#FC9C33F" : "#FFFFFF",
                dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#333",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
                options: mode === "light" ? "#fafafa" : "#666",
                fontecor: mode === "light" ? "#000" : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#333",
                bordabox: mode === "light" ? "#eee" : "#333",
                newmessagebox: mode === "light" ? "#eee" : "#333",
                inputdigita: mode === "light" ? "#fff" : "#333",
                contactdrawer: mode === "light" ? "#fff" : "#333",
                announcements: mode === "light" ? "#ededed" : "#333",
                login: mode === "light" ? "#fff" : "#1C1C1C",
                announcementspopover: mode === "light" ? "#fff" : "#333",
                chatlist: mode === "light" ? "#eee" : "#333",
                boxlist: mode === "light" ? "#ededed" : "#333",
                boxchatlist: mode === "light" ? "#ededed" : "#333",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#333",
                barraSuperior: mode === "light" ? "linear-gradient(to right, #ffb710, #ffb710, #ffb710)" : "#666",
                boxticket: mode === "light" ? "#EEE" : "#333",
                campaigntab: mode === "light" ? "#ededed" : "#333",
                drawerIcons: mode === "light" ? "#ffb710" : "inherit",
                drawerBackground: mode === "light" ? "##fff" : "#333",
                drawerText: mode === "light" ? "#000" : "#fff",

                // background: '#10175b',
                // color: 'white',

            },
            mode,
            appLogoDark,
            appLogoLight,
            appLogoFavicon,
            appName,
            chatlistLight,
            chatlistDark,
            boxLeftLight,
            boxLeftDark,
            boxRightLight,
            boxRightDark,
            calculatedLogoDark: () => {
                if (appLogoDark === defaultLogoDark && appLogoLight !== defaultLogoLight) {
                    return appLogoLight;
                }
                return appLogoDark;
            },
            calculatedLogoLight: () => {
                if (appLogoDark !== defaultLogoDark && appLogoLight === defaultLogoLight) {
                    return appLogoDark;
                }
                return appLogoLight;
            }
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
        const PAGE_TAB_TITLE = {
            "connect": "Connect Flow",
            "tarifando": "Tarifando Telecom",
            "orbsofty": "Orbsofty"
        }
        document.title = PAGE_TAB_TITLE[process.env.REACT_APP_THEME_LOGO];
        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode ? mode : "light");
    }, [mode]);

    return (
            <DashboardContext.Provider value={{
                colorMode,
                counters,
                setCounters,
                attendants,
                setAttendants,
                lastTickets,
                setLastTickets,
                allTicketsCount,
                setAllTicketsCount,
                filterType,
                setFilterType,
                reportCount,
                setReportCounts,
                period,
                setPeriod,
                companyDueDate,
                setCompanyDueDate,
                dateTo,
                setDateTo,
                dateFrom,
                setDateFrom,
                serviceQuality,
                setServiceQuality}}>
                <ColorModeContext.Provider value={{colorMode}}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            {/* Ensure AuthContext wraps around components that need user data */}
                            <AuthProvider>
                                <WavoipProvider>
                                    <Routes />
                                </WavoipProvider>
                            </AuthProvider>
                        </QueryClientProvider>
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </DashboardContext.Provider>
    );
};

export default App;
