import React from "react";
import {
  TextField,
  Grid,
} from "@material-ui/core";

const HoursTimeSelector = ({ schedule, classes, handleChange, setSchedule }) => {
  return (
      <>
        {Object.keys(schedule).map((day) => (
          <Grid container spacing={2} key={day} alignItems="center">
            <Grid item xs={3}>
              <span className={classes.dayLabel}>
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Início"
                type="time"
                value={schedule[day].inicio}
                onChange={(e) => handleChange(day, "inicio", e.target.value, setSchedule)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  step: 300,
                  min: "00:00",
                  max: "19:00",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Fim"
                type="time"
                value={schedule[day].fim}
                onChange={(e) => handleChange(day, "fim", e.target.value, setSchedule)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  step: 300,
                  min: "00:00",
                  max: "19:00",
                }}
              />
            </Grid>
          </Grid>
        ))}
      </>
  );
};

export default HoursTimeSelector;
