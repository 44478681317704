import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import api from "../../services/api";
import truncateText from "../../utils/truncateText";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Poppins', sans-serif",
  },
  titulo: {
    color: `${theme.palette.primary.mainReverseByWhite}`,
    marginBottom: theme.spacing(2),
    fontFamily: "'Poppins', sans-serif",
  },
  tabela: {
    minWidth: 500,
    fontFamily: "'Poppins', sans-serif",
  },
  cabecalhoTabela: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontFamily: "'Poppins', sans-serif",
  },
  carregando: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "150px",
    fontFamily: "'Poppins', sans-serif",
  },
  erro: {
    color: theme.palette.error.main,
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
  },
  modal: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    backgroundColor: "transparent",
    color: theme.palette.primary.mainReverseByWhite,
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.primary.mainReverseByWhite,
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        fontFamily: "'Poppins', sans-serif",
    },
  },
}));

const formatarDataBrasileira = (data) => {
  if (!data) return "N/A";
  const dataObj = new Date(data);
  return dataObj.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const ModalDetalhesCampanha = ({ ticketId, open, onClose }) => {
  const classes = useStyles();
  const [dadosTicket, setDadosTicket] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [erro, setErro] = useState(null);

  useEffect(() => {
    const buscarDadosTicket = async () => {
      setCarregando(true);
      setErro(null);

      try {
        const response = await api.get(`/campaigns/ticket/${ticketId}`);
        setDadosTicket(response.data);
      } catch (err) {
        setErro("Erro ao carregar os dados do ticket.");
      } finally {
        setCarregando(false);
      }
    };

    if (ticketId && open) {
      buscarDadosTicket();
    }
  }, [ticketId, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.modal }}
    >
      <DialogTitle className={classes.titulo}>Detalhes da Campanha</DialogTitle>
      <DialogContent>
        {carregando && (
          <div className={classes.carregando}>
            <CircularProgress />
          </div>
        )}
        {erro && <Typography className={classes.erro}>{erro}</Typography>}
        {!carregando && !erro && dadosTicket.length > 0 && (
          <TableContainer>
            <Table className={classes.tabela}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cabecalhoTabela}>ID</TableCell>
                  <TableCell className={classes.cabecalhoTabela}>Mensagem</TableCell>
                  <TableCell className={classes.cabecalhoTabela}>ID da campanha</TableCell>
                  <TableCell className={classes.cabecalhoTabela}>Entregue em</TableCell>
                  <TableCell className={classes.cabecalhoTabela}>Processando</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dadosTicket.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.id}</TableCell>
                    <TableCell>
                      <Tooltip title={ticket?.message || ""} arrow>
                        <span>{truncateText(ticket?.message, 20)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{ticket.campaignId}</TableCell>
                    <TableCell>
                      {ticket.deliveredAt ? formatarDataBrasileira(ticket.deliveredAt) : "N/A"}
                    </TableCell>
                    <TableCell>{ticket.processing ? "Sim" : "Não"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!carregando && !erro && dadosTicket.length === 0 && (
          <Typography className={classes.erro}>
            Nenhum dado disponível.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.button}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDetalhesCampanha;
