import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";



const useStyles = makeStyles((theme) => ({
    
    container: {
        padding: "0 2rem",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: 340,
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },

    dashBoardFirstItem: {
        background: theme.palette.primary.mainGradient,
        color: theme.palette.primary.main
    },

    dashboardHeaderItem: {
        background: theme.palette.primary.mainGradient,
        color: "white",
    },

    graphsLabel: {
        color: theme.palette.blackOrWhite
    },

    graphsBox: {
        background: theme.palette.primary.mainGradient,
        color: theme.palette.primary.mainReverseByWhite,
        fontFamily: "Roboto",
        transition: ".3s"
    },
    filterButton: {
        color: theme.palette.primary.main + " !important",
        border: "1px solid " + theme.palette.primary.main + " !important",
        background: "#FFFFFF50"
    }
}));

export default useStyles;
