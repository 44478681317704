import React, { useState } from "react";
import { Grid, Typography, Button, ClickAwayListener } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { NetworkCell } from "@material-ui/icons";

const ConnectionsDropdown = ({ campaign, classes, theme }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      {campaign?.whatsappDetails && campaign?.whatsappDetails?.length > 0 && (
        <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
          <div
            className={classes.boxItemStyles}
            style={{
              padding: "16px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              position: "relative", // Adiciona o posicionamento relativo ao pai
            }}
          >
            <Typography className={classes.titleStyles}>Conexões do WhatsApp</Typography>
            <Button
              onClick={toggleDropdown}
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                backgroundColor: theme?.palette?.primary?.main || "#000",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {isDropdownOpen ? "Fechar Conexões" : "Mostrar Conexões"}
            </Button>
            {isDropdownOpen && (
              <ClickAwayListener onClickAway={closeDropdown}>
                <div
                  style={{
                    position: "absolute", // Define o dropdown como absoluto
                    top: "100%", // Faz com que o dropdown apareça logo abaixo do botão
                    left: 0,
                    width: "100%", // Ajusta para alinhar ao botão
                    marginTop: "8px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    zIndex: 10,
                    padding: "8px",
                  }}
                >
                  {campaign.whatsappDetails.map((detail) => (
                    <div
                      key={detail.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px 12px",
                        borderBottom: "1px solid #f0f0f0",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                      >
                        {detail.name}
                      </Typography>
                      <NetworkCell style={{
                          color: theme?.palette?.primary?.main || "#000",
                          fontSize: "1.5rem",
                        }}/>
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
          </div>
        </Grid>
      )}
    </>
  );
};

export default ConnectionsDropdown;
