import faviconFlow from '../favicons/faviconConnect.ico';
import favicon from '../favicons/favicon.ico';
import faviconPurple from '../favicons/faviconp.ico';
import faviconOrbsofty from '../favicons/faviconOrbsofty.ico';

const faviconTheme = {
    "tarifando": favicon,
    "purple": faviconPurple,
    "connect": faviconFlow,
    "orbsofty": faviconOrbsofty
}

export default faviconTheme;
