import { useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const ScatterChart = ({ tickets }) => {

    const convertSupportTimeToMinutes = (supportTime) => {
        const timeParts = supportTime.match(/\d+/g);
        const days = parseInt(timeParts[0], 10);
        const hours = parseInt(timeParts[1], 10);
        const minutes = parseInt(timeParts[2], 10);
        const seconds = parseInt(timeParts[3], 10);

        return days * 1440 + hours * 60 + minutes + Math.floor(seconds / 60);
    };

    const { labels, supportTimeData } = useMemo(() => {
        const ticketArray = Array.isArray(tickets) ? tickets : [tickets];

        if (ticketArray.length === 0) {
            return {
                labels: ['Placeholder'],
                supportTimeData: [30],
            };
        }

        const labels = ticketArray?.map(ticket => {
            const fullName = ticket?.contactName || ticket?.whatsappName || 'Desconhecido';
            return fullName.split(' ')[0];
        });

        const supportTimeData = ticketArray.map(ticket =>
            ticket?.supportTime ? convertSupportTimeToMinutes(ticket.supportTime) : 0
        );

        return { labels, supportTimeData };
    }, [tickets]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Tempo Médio de Atendimento (minutos)',
                data: supportTimeData,
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tempo Médio de Atendimento (minutos)',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Atendentes',
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45,
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw} minutos`,
                },
            },
            legend: {
                position: 'top',
                labels: {
                    fontColor: "black",
                    fontWeight: 700
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 20,
                left: 20,
                bottom: 30,
            },
        },
    };
    const theme = useTheme();
    return (
        <div style={{ width: '100%', height: '220px' }}>
            <h3 style={{color: theme.palette.blackOrWhite, fontWeight: "400"}}>{`Tempo Médio de Atendimento`}</h3>
            <Line data={data} options={options} />
        </div>
    );
};

export default ScatterChart;
