import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
  },
  dialogTitle: {
    color: theme.palette.primary.mainReverseByWhite,
    display: "flex",
    justifyContent: "space-between"
  },
  dayLabel: {
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
  },
  button: {
    color: theme.palette.primary.main,
  },
  expandButton: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;