import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Box, LinearProgress, useTheme } from '@material-ui/core';
import { i18n } from '../../translate/i18n';
import api from "../../services/api";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import * as XLSX from "xlsx";
import { toast } from 'react-toastify';
import useStyles from './style';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';

const ContactImportWpModal = ({ isOpen, handleClose, userProfile }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const initialContact = { name: "", number: "", error: "" };

  const [contactsToImport, setContactsToImport] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0); // Armazena o total de contatos a serem importados
  const [statusMessage, setStatusMessage] = useState("");
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [isImporting, setIsImporting] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showConcluedStyles, setShowConcluedStyles] = useState(false);
  const [concludedMessage, setConcludedMessage] = useState("")

  const { user } = useContext(AuthContext);

  const handleClosed = useCallback(() => {
    setContactsToImport([]);
    setTotalContacts(0);
    setStatusMessage("");
    setConcludedMessage("")
    setCurrentContact(initialContact);
    setSuccessCount(0);
    setErrorCount(0);
    setProgress(0);
    setIsImporting(false);
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    const importContacts = async () => {
      if(contactsToImport?.length > 1000) {
        toastError("Não é possível enviar uma planilha com mais de 1000 contatos. Por favor, reduza a quantidade e tente novamente.");
        setIsImporting(false);
        setContactsToImport([]);
        setTotalContacts(0);
        setStatusMessage("");
        setCurrentContact(initialContact);
        setSuccessCount(0);
        setErrorCount(0);
        setProgress(0);
        return
      }
      if ((successCount || errorCount) && ((successCount + errorCount) === totalContacts)) {
        setShowConcluedStyles(true);
        toast.success(`Contatos adicionados: ${successCount}. Contatos não encontrados: ${errorCount}`);
        setConcludedMessage(
          <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
            <span>
              Contatos adicionados: <span style={{ color: "green" }}> {` ${successCount}`}</span>.
            </span>
            <span>
              Contatos não encontrados: <span style={{ color: "red" }}> {` ${errorCount}`}</span>.
            </span>
          </div>
        );
      }
  
      if (contactsToImport?.length && !isImporting) {
        setIsImporting(true);
        setStatusMessage(`Importando ${contactsToImport.length} contatos. Não saia desta tela até concluir!`);
        setSuccessCount(0);
        setErrorCount(0);
        setProgress(0);
        setConcludedMessage("");
        for (let index = 0; index < contactsToImport.length; index++) {
          const item = contactsToImport[index];
  
          try {
            await api.post(`/contactsImport`, {
              name: item.name,
              number: item.number.toString(),
              email: item.email,
            });
  
            setCurrentContact({ name: item.name, number: item.number, error: "success" });
            setSuccessCount((prev) => prev + 1);
          } catch (err) {
            setCurrentContact({ name: item.name, number: item.number, error: err });
            setErrorCount((prev) => prev + 1);
          }
  
          const newProgress = Math.round(((index + 1) / contactsToImport.length) * 100);
          setProgress(newProgress);
        }
  
        if (successCount + errorCount > 0) {
          setStatusMessage(`Importação concluída com êxito! ${successCount} contatos adicionados e ${errorCount} não encontrados.`);
          toast.success(`Importação concluída! ${successCount} contatos adicionados e ${errorCount} não encontrados.`);
        }
  
        setIsImporting(false);
        setContactsToImport([]);
        setTotalContacts(0);
        setStatusMessage("");
        setCurrentContact(initialContact);
        setSuccessCount(0);
        setErrorCount(0);
        setProgress(0);
      }
    };
  
    importContacts();
  }, [contactsToImport]);

  const handleImportChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);

      setContactsToImport(data);
      setTotalContacts(data.length);
    };
    
    reader.readAsBinaryString(file);
    toast.success("Importando contatos");
  };

  return (
    <Dialog PaperProps={{ style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` } }} fullWidth open={isOpen} onClose={isImporting ? () => { } : handleClosed}>
      <DialogTitle className={classes.modalTitle}>{i18n.t("Importação de contatos")}</DialogTitle>
      {showConcluedStyles && (
        <span className={classes?.concluedStyles}>{concludedMessage}</span>
      )}
      <h4 style={{ textAlign: "center" }}>{statusMessage}</h4>

      {progress === 100 && (
        <div className={classes.answersLabel} style={{ marginTop: "1rem" }}>
          <p style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: "green" }}>Sucesso: {successCount}</span> -
            <span style={{ color: "red" }}> Não encontrado: {errorCount}</span>
          </p>
        </div>
      )}

      <Box style={{ padding: "0px 10px 10px" }}>
        <div className={classes.multFieldLine}>
          <div style={{ minWidth: "100%" }}>
            {contactsToImport?.length ? (
              <>
                <div className={classes.label}>
                  {currentContact?.name ? (
                    <Button fullWidth disabled className={classes.btnStyles} variant="text">
                      {`${currentContact?.name} => ${currentContact?.number} `}
                    </Button>
                  ) : null}
                </div>

                <div style={{ padding: "0 18px" }}>
                  <LinearProgress variant="determinate" value={progress} />
                </div>

                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <strong>{progress}%</strong>
                </div>
              </>
            ) : (
              <>
                <label className={classes.label} htmlFor="contacts">
                  <AttachFileIcon /> <div>{i18n.t("contactImportWpModal.buttons.import")}</div>
                </label>
                <input
                  className={classes.uploadInput}
                  name="contacts"
                  id="contacts"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleImportChange}
                />
              </>
            )}
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default ContactImportWpModal;
