import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logoP from "../../assets/new-logo-cut.png";
import logo from "../../assets/logo.png";
import logoConnect from "../../assets/logo-signed-flat.png";
import logoOrbsofty from "../../assets/logotipo-orbsofty-07.png";

const themeLogo = {
  "tarifando": logo,
  "purple": logoP,
  "connect": logoConnect,
  "orbsofty": logoOrbsofty
}

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingBottom: "50px",
    width: "400px",
    marginBottom: theme.spacing(3),
  },
}));

const LoginHeader = () => {
  const classes = useStyles();
  const chosenLogo = themeLogo[process.env.REACT_APP_THEME_LOGO]
  return (
    <>
      <header className="cmp-login__header">
        <div className="cmp-login__max-container">
          <img src={chosenLogo} alt="Logo" className={classes.logo} />
        </div>
      </header>
    </>
  );
};

export default LoginHeader;
