import { toast } from "react-toastify";
import api from "../../../../services/api";
import * as XLSX from "xlsx";

const handleFileUpload = async (event, setIsLoading, setBlacklist, setTotalPages, currentPage) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);
    try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let contacts = XLSX.utils.sheet_to_json(sheet);

        if (contacts?.length > 1000) {
            toast.error("Por favor adicione no máximo 1000 contatos por vez.");
            return;
        }

        await api.post("/blacklist", { contacts });
        const response = await api.get(`/blacklist?page=${currentPage}`);
        
        const filteredContacts = response.data.data.filter((contact, index, self) => {
            return (
                index ===
                self.findIndex((c) => c.number === contact.number)
            );
        });

        setBlacklist(filteredContacts);
        setTotalPages(response.data.totalPages);

        toast.success(`Você adicionou ${contacts?.length} contatos com sucesso!`);
    } catch (error) {
        toast.error("Planilha no formato inválido.");
    } finally {
        setIsLoading(false);
    }
};

export default handleFileUpload;
