import { useTheme } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import CloseIcon from "@material-ui/icons/Close";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { Phone, CallEnd, Person as PersonIcon, Contacts as ContactsIcon } from "@material-ui/icons";
import callingSound from "../../assets/sounds/calling.mp3";
import { WavoipContext } from "../../context/WavoipContext/WhavoipContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import useStyles from "./style";
import "./style.css";
import Draggable, { DraggableCore } from 'react-draggable';
import api from "../../services/api";
import Pagination from "@material-ui/lab/Pagination";
import { Mic, MicOff } from "@material-ui/icons";
import toastError from "../../errors/toastError";

const PhonePopup = ({ forceOnMenu = false }) => {
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const waData = useContext(WavoipContext);
    const {
        isCalling,
        callDuration,
        audioRef,
        handleWavoipCall,
        acceptCall,
        callReceived,
        setWavoipUser,
        rejectCall,
        wavoipActiveContact,
        activeTicket,
        isOpen,
        setIsOpen,
        isActive,
        setIsActive,
        phoneNumber,
        setPhoneNumber,
        callStatus,
        mute,
        unMute,
        isMuted,
        setIsMuted
    } = waData;

    const [onMenu, setOnMenu] = useState(false);
    const [contactsModalOpen, setContactsModalOpen] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    const contactModalRef = useRef(null);
    const phoneInputRef = useRef(null);

    const toggleMute = () => {
        if (isMuted) {
            unMute();
            setIsMuted(false);
        } else {
            mute();
            setIsMuted(true);

        }
    }

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const { data } = await api.get("/contacts/", {
                params: { searchParam, pageNumber },
            });
            setContacts(data.contacts);
            setCount(data.count)
        } catch (error) {
            console.error("Error fetching contacts:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContacts();
    }, [searchParam, pageNumber]);

    useEffect(() => {
        setOnMenu(location.pathname.includes("/tickets") || forceOnMenu);
    }, [location.pathname]);

    useEffect(() => {
        setWavoipUser(user);
    }, []);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        toggleActiveState();
    };

    const toggleActiveState = () => {
        setIsActive(!isActive);
    };

    const handleButtonPress = (digit) => {
        if (phoneNumber.length < 16) {
            setPhoneNumber(formatPhoneNumber(phoneNumber + digit));
        }
    };

    const handleClear = () => {
        setPhoneNumber(phoneNumber.slice(0, -1));
    };

    useEffect(() => {
        if (contactsModalOpen) {
            const handleClickOutside = (event) => {
                if (contactModalRef.current && !contactModalRef.current.contains(event.target)) {
                    // Se quiser evitar fechar o modal, adicione uma condição adicional
                    if (!event.target.classList.contains("no-close")) {
                        setContactsModalOpen(false);
                    }
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [contactsModalOpen]);


    useEffect(() => {
        if (isOpen) {
            const handleClickOutside = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setIsOpen(false);
                    setIsActive(false);
                }
            };
            if (phoneInputRef.current) {
                phoneInputRef.current.focus();
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isOpen]);

    const formatPhoneNumber = (number) => {
        const digitsOnly = number.replace(/\D/g, "");
        if (digitsOnly.length <= 2) return digitsOnly;
        if (digitsOnly.length <= 4) return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2)}`;
        if (digitsOnly.length <= 9) return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(4)}`;
        return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(4, 9)}-${digitsOnly.slice(9)}`;
    };

    const classes = useStyles();
    const theme = useTheme();

    const handleCallFromContact = (contactNumber, contact) => {
        if (user?.cannotCall) {
            toastError("Você está sem permissões para realizar ligações");
        }
        setPhoneNumber(contactNumber);
        sessionStorage.setItem("ticketContactInfo", JSON.stringify(contact));
        setContactsModalOpen(false);
        handleWavoipCall(contactNumber, user, null, { profile_picture: contact.profilePicUrl, phone: contact.number, from_tag: contact.name });
    };

    const hideContactNumberActive = localStorage.getItem("hideContactNumberActive") === "true";

    return (
        <>
            <audio ref={audioRef} loop>
                <source src={callingSound} type="audio/mpeg" />
                Seu navegador não suporta a tag de áudio.
            </audio>
            <button
                className={`${classes.button} ${callReceived ? "cmp-phone--blink-button" : ""}`}
                onClick={() => togglePopup()}
                style={{
                    backgroundColor: isActive ? "green" : "red", // Define a cor de fundo
                    color: "white",
                    position: onMenu ? "relative" : "fixed",
                    right: onMenu ? 0 : "2rem",
                    bottom: onMenu ? 0 : "1rem",
                    padding: onMenu ? "10px" : "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                }}
            >
                {!isActive ? <PhoneIcon style={{color: theme.palette.primary.main}} /> : <CloseIcon style={{color: theme.palette.primary.main}}/>}
            </button>

            {isOpen && (
                <Draggable >
                    <div className={classes.popup} ref={modalRef}>
                        <div className={classes.screen}>
                            {(isCalling || callReceived) ? (
                                <div className={classes.callingScreen}>
                                    {((!wavoipActiveContact?.profile_picture) && !(isCalling && wavoipActiveContact?.profile_picture)) && <div className={classes.avatar}>
                                        {activeTicket ? <img style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }} src={activeTicket?.contact?.profilePicUrl} alt="Profile Preview" /> : <PersonIcon className={classes.avatarIcon} />}
                                    </div>}
                                    {((isCalling && wavoipActiveContact?.profile_picture) || callReceived) && <div className={classes.avatar}>
                                        <img style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }} src={wavoipActiveContact?.profile_picture} alt="Profile Preview" />
                                    </div>}
                                    {callStatus === "calling" && <div style={{ fontSize: "14px" }} className={classes.phoneNumber}>Ligando</div>}
                                    {callStatus === "connected" && <div style={{ fontSize: "14px" }} className={classes.phoneNumber}>Conectado</div>}
                                    {callStatus === "accept_elsewhere" && <div style={{ fontSize: "14px" }} className={classes.phoneNumber}>Atendido por outro usuário</div>}
                                    {wavoipActiveContact?.from_tag && (
                                        <div className={classes.phoneNumber}>
                                            {wavoipActiveContact?.from_tag}
                                        </div>
                                    )}
                                    {!(hideContactNumberActive && user.profile === "user") && (
                                        <>
                                            {!wavoipActiveContact?.from_tag && !wavoipActiveContact?.phone && (
                                                <div className={classes.phoneNumber}>
                                                    {phoneNumber || (activeTicket?.contact?.number || "00 00 00000-0000")}
                                                </div>
                                            )}

                                            {wavoipActiveContact?.phone && (
                                                <div className={classes.phoneNumber}>
                                                    {wavoipActiveContact?.phone || (activeTicket?.contact?.number || "00 00 00000-0000")}
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className={classes.callDuration}>{`${callDuration}s`}</div>
                                </div>
                            ) : (
                                <>
                                    <div className={classes.displayContainer}>
                                        <input
                                            type="text"
                                            className={classes.displayInput}
                                            value={phoneNumber}
                                            ref={phoneInputRef}
                                            onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
                                            placeholder="00 00 00000-0000"
                                        />
                                        <button
                                            className={classes.clearButton}
                                            onClick={handleClear}
                                        >
                                            <BackspaceIcon />
                                        </button>
                                    </div>

                                    <div className={classes.keypad}>
                                        {Array.from({ length: 9 }, (_, i) => (
                                            <button
                                                key={i + 1}
                                                className={classes.key}
                                                onClick={() => handleButtonPress((i + 1).toString())}
                                            >
                                                {i + 1}
                                            </button>
                                        ))}
                                        <button
                                            className={classes.key}
                                            onClick={() => handleButtonPress("*")}
                                        >
                                            *
                                        </button>
                                        <button
                                            className={classes.key}
                                            onClick={() => handleButtonPress("0")}
                                        >
                                            0
                                        </button>
                                        <button
                                            className={classes.key}
                                            onClick={() => handleButtonPress("#")}
                                        >
                                            #
                                        </button>
                                    </div>
                                </>
                            )}
                            {!callReceived && <div
                                className={`${classes.actionButtonsContainer} ${isCalling ? classes.callingButton : ""}`}
                            >
                                <button
                                    disabled={isCalling}
                                    className={`${classes.contactModalButton} no-close`}
                                    onClick={() => setContactsModalOpen((prev) => !prev)}
                                    style={{ color: "white" }}
                                >
                                    <ContactsIcon className="no-close" />
                                </button>
                                <button
                                    onClick={() => {
                                        handleWavoipCall(phoneNumber, user);
                                        toggleActiveState(); // Altera o estado ativo/inativo ao ligar/desligar
                                    }}
                                    className={
                                        isCalling
                                            ? classes.callButtonRed
                                            : classes.callButton
                                    }
                                >
                                    {isCalling ? (
                                        <CallEnd
                                            style={{
                                                color: theme.palette.primary.mainReverseByWhite,
                                            }}
                                        />
                                    ) : (
                                        <Phone
                                            style={{
                                                color: theme.palette.primary.mainReverseByWhite,
                                            }}
                                        />
                                    )}
                                </button>
                                <div className={classes.actionButtonsContainer}>
                                    <button
                                        onClick={toggleMute}
                                        className={classes.contactModalButton2}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {isMuted ? <MicOff /> : <Mic />}
                                    </button>
                                </div>

                            </div>}
                            {(callReceived && (callStatus !== "accept_elsewhere")) && (
                                <div className={classes.incomingCall}>
                                    <button
                                        className={classes.callButtonRed}
                                        onClick={() => rejectCall()}
                                    >
                                        <Phone
                                            style={{
                                                color: theme.palette.primary.mainReverseByWhite,
                                            }}
                                        />
                                    </button>
                                    <button
                                        className={classes.callButton}
                                        onClick={() => acceptCall()}
                                    >
                                        <Phone
                                            style={{
                                                color: theme.palette.primary.mainReverseByWhite,
                                            }}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                        {contactsModalOpen && (
                            <div className={classes.contactsModal} ref={contactModalRef}>
                                <div className={classes.modalHeader}>
                                    <h2>Contatos</h2>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Pesquisar..."
                                    value={searchParam}
                                    onChange={(e) => setSearchParam(e.target.value)}
                                    className={classes.searchInput}
                                />
                                {loading ? (
                                    <p className={classes.loadingText}>Carregando...</p>
                                ) : (
                                    <ul className={classes.contactList}>
                                        {contacts.map((contact) => (
                                            <li key={contact.id} className={classes.contactItem}>
                                                <div className={classes.contactDetails}>
                                                    <span className={classes.contactName}>{((!isNaN(contact.name) && hideContactNumberActive) && user.profile === "user") ? "Sem permissão" : contact.name}</span>
                                                    {!(hideContactNumberActive && user.profile === "user") && <span className={classes.contactPhone}>{contact?.number}</span>}
                                                </div>
                                                <button
                                                    className={classes.callButton}
                                                    onClick={() => handleCallFromContact(contact?.number, contact)}
                                                >
                                                    <Phone
                                                        style={{
                                                            color: theme.palette.primary.mainReverseByWhite,
                                                        }}
                                                    />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "1rem 0", overflow: "auto" }}>
                                    <Pagination
                                        count={Math.ceil(count / 20)}
                                        page={pageNumber}
                                        color="primary"
                                        onChange={(event, value) => setPageNumber(value)}
                                        sx={{
                                            color: 'white', // Define a cor do texto
                                            '& .MuiPaginationItem-root': {
                                                color: 'white', // Ícones e números brancos
                                            },
                                            '& .MuiPaginationItem-root.Mui-selected': {
                                                backgroundColor: 'white', // Cor de fundo do item selecionado
                                                color: '#ec6724', // Cor do texto do item selecionado
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                    </div>
                </Draggable>
            )}

        </>
    );
};

export default PhonePopup;
